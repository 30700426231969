import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTestTube = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 20 20" {...props}>
		<path
			fill="currentColor"
			d="m9.95 1.564.708.707-9.193 9.193A4.97 4.97 0 0 0 0 15c0 1.336.521 2.592 1.465 3.535A4.97 4.97 0 0 0 5 20a4.97 4.97 0 0 0 3.535-1.465l9.193-9.193.707.708 1.414-1.414L11.364.15zM7.121 17.12c-1.133 1.133-3.109 1.133-4.242 0A2.98 2.98 0 0 1 2 15c0-.802.313-1.555.879-2.121l.879-.88h8.484zM14.242 10H5.758l6.314-6.314 4.242 4.242z"
		/>
	</svg>
)
export default SvgTestTube
