import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDeaf = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M10.125 12.188a1.312 1.312 0 1 1-2.625 0 3.754 3.754 0 0 1 3.75-3.75 3.754 3.754 0 0 1 3.75 3.75 1.312 1.312 0 1 1-2.625 0 1.126 1.126 0 0 0-2.25 0m1.125-8.25c-4.55 0-8.25 3.7-8.25 8.25a1.312 1.312 0 1 0 2.625 0 5.63 5.63 0 0 1 5.625-5.626 5.63 5.63 0 0 1 5.625 5.626c0 3.523-3.328 3.295-3.375 6.732v.017a2.44 2.44 0 0 1-2.437 2.438 1.312 1.312 0 1 0 0 2.625 5.07 5.07 0 0 0 5.062-5.052c.028-1.614 3.375-2.261 3.375-6.76 0-4.55-3.7-8.25-8.25-8.25M23.835 1.49 22.51.165a.563.563 0 0 0-.795 0l-4.08 4.08a.56.56 0 0 0 0 .795l1.326 1.326c.22.22.576.22.796 0l4.08-4.08a.563.563 0 0 0 0-.795M7.92 14.754a.563.563 0 0 0-.795 0l-6.96 6.96a.563.563 0 0 0 0 .795l1.326 1.326c.22.22.575.22.795 0l6.96-6.96a.563.563 0 0 0 0-.796z"
		/>
	</svg>
)
export default SvgDeaf
