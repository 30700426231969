import { forwardRef } from 'react'
import { IInputFileItem } from './IInputFile'
import { Icon } from '@/components/clientComponents/Icon'
import { Text } from '@/components/sharedComponents/Text'
import { cn } from '@/lib/utils'

export const InputFileItem = forwardRef<HTMLInputElement, IInputFileItem>(
	(
		{
			disabled = false,
			'data-testid': dataTestId = 'occipital-input-file',
			label = 'Galería',
			id = label,
			name = 'image',
      className,
			classNameContainer,
			accept = 'image/*', // Valor por defecto para imágenes
			capture = 'user',
			...props
		},
		ref,
	) => {
		return (
			<>
				<div className={cn( "flex align-middle justify-center items-center relative", disabled && "opacity-50 cursor-not-allowed", classNameContainer)}>
					<Icon size="size-5" name={name} aria-hidden />
					<Text
						tag="label"
						htmlFor={id}
						size="text-s"
						className="ml-2"
					>
						{label}
					</Text>
					<input
						ref={ref}
						data-testid={dataTestId}
						disabled={disabled}
						aria-disabled={disabled}
						className={cn("absolute top-0 left-0 h-full w-full opacity-0", className)}
						id={id}
						type="file"
						accept={accept}
						capture={capture} // Usar "user" para la cámara frontal
						aria-describedby="selected-file"
						{...props}
					/>
				</div>
			</>
		)
	},
)

InputFileItem.displayName = 'InputFileItem'
