import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Icon } from '../Icon';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { cn } from '@/lib/utils';

/**
 * El componente raíz de `DropdownMenu` que envuelve todo el menú desplegable.
 * Utiliza el componente `Root` de Radix UI.
 *
 * @component
 */

const OptionsMenuRoot = DropdownMenu.Root;

/**
 * El componente `OptionsMenuPortal` se utiliza para renderizar el contenido del menú
 * en un portal, lo que permite que el menú se muestre fuera del flujo normal del DOM.
 *
 * @component
 */

const OptionsMenuPortal = DropdownMenu.Portal;

/**
 * El componente `OptionsMenuContent` representa el contenedor del contenido del menú desplegable.
 * Aquí se define el estilo y la estructura del menú.
 *
 * @component
 */

const OptionsMenuContent = DropdownMenu.Content;

/**
 * El componente `OptionsMenuTrigger` sirve como disparador del menú desplegable.
 * Envuelve un botón que, al hacer clic, abre el menú.
 *
 * @component
 */

const OptionsMenuTrigger = ({ className, ...props }: ComponentPropsWithoutRef<typeof DropdownMenu.Trigger>) => (
  <DropdownMenu.Trigger asChild>
    <button
      aria-label="Open menu"
      className={cn("p-2 bg-transparent rounded-full hover:bg-gray-100 focus:outline-none", className)}
      {...props}
    >
      <Icon name="ellipsisV" />
    </button>
  </DropdownMenu.Trigger>
);

interface OptionsMenuItemProps extends ComponentPropsWithoutRef<typeof DropdownMenu.Item> {
  showArrow?: boolean;
  children: ReactNode;
}

/**
 * El componente `OptionsMenuItem` representa un ítem individual dentro del menú desplegable.
 * Se puede personalizar para mostrar un ícono de flecha opcional a la derecha.
 *
 * @component
 */

const OptionsMenuItem = ({ showArrow, className, children, ...props }: OptionsMenuItemProps) => (
  <DropdownMenu.Item
    {...props}
    className={cn("p-2 flex justify-between items-center text-gray-800 hover:bg-gray-100 cursor-pointer rounded-md focus-visible:outline-none active:bg-primary-100", className)}
  >
    <span className={cn('text-gray-600', className)}>{children}</span>
    {showArrow && <Icon name="chevronRight" />}
  </DropdownMenu.Item>
);

export {
  OptionsMenuContent,
  OptionsMenuTrigger,
  OptionsMenuPortal,
  OptionsMenuRoot,
  OptionsMenuItem,
}
