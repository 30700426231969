import * as React from 'react'
import type { SVGProps } from 'react'
const SvgThumbsDown = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12.7503 1.5H14.7528C15.1772 1.49249 15.5897 1.6411 15.9118 1.91761C16.234 2.19412 16.4434 2.57929 16.5003 3V8.25C16.4434 8.6707 16.234 9.05587 15.9118 9.33238C15.5897 9.6089 15.1772 9.7575 14.7528 9.75H12.7503M7.50027 11.25V14.25C7.50027 14.8467 7.73732 15.419 8.15928 15.841C8.58123 16.2629 9.15353 16.5 9.75027 16.5L12.7503 9.75V1.5H4.29027C3.92852 1.49591 3.57748 1.62269 3.30184 1.85699C3.02619 2.09129 2.84451 2.41732 2.79027 2.775L1.75527 9.525C1.72264 9.73998 1.73714 9.95949 1.79776 10.1683C1.85839 10.3771 1.96369 10.5703 2.10637 10.7344C2.24906 10.8984 2.42571 11.0295 2.62409 11.1186C2.82247 11.2076 3.03784 11.2525 3.25527 11.25H7.50027Z"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
			strokeWidth="1.5"
		/>
	</svg>
)
export default SvgThumbsDown
