import { Suspense, useMemo } from 'react'
import { icons } from './constants'
import { IIcon } from './IIcon'
import { cn } from '@/lib/utils'

export default function Icon({
	action,
	isClickable = false,
	isInline = false,
	color = 'inherit',
	size = 'size-4',
	name = 'starFill',
	'aria-hidden': ariaHidden = true,
	'data-testid': dataTestId = 'occipital-icon',
	className = undefined,
	stroke = 'stroke-0',
	...props
}: IIcon) {
	const SvgIcon = useMemo(() => icons[name], [name])

	const clickable = isClickable ? 'pointer' : ''
	const inline = isInline ? 'inline' : ''

	if (!SvgIcon) return null

	return (
		<>
			<div
				className={cn(
					'flex shrink-0',
					size,
					color,
					clickable,
					inline,
					className,
				)}
				onClick={action}
				aria-hidden={ariaHidden}
			>
				<Suspense fallback={null}>
					<SvgIcon
						fill="primary"
						aria-hidden={ariaHidden}
						data-visible={
							props.visibility === 'visible' ? 'true' : 'false'
						}
						data-testid={dataTestId}
						className={cn('stroke-0 stroke-current', stroke)}
						{...props}
					/>
				</Suspense>
			</div>
		</>
	)
}
