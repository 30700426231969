import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCartPlus = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M21.03 14.667H8.815L9.089 16h11.184a1 1 0 0 1 .975 1.222l-.23 1.011a2.333 2.333 0 0 1-1.051 4.434 2.343 2.343 0 0 1-2.299-2.269 2.33 2.33 0 0 1 .7-1.731H9.633a2.333 2.333 0 1 1-2.794-.357L3.913 4H1a1 1 0 0 1-1-1v-.667a1 1 0 0 1 1-1h4.272a1 1 0 0 1 .98.8L6.634 4H23a1 1 0 0 1 .975 1.222l-1.97 8.667a1 1 0 0 1-.975.778M17 8.334h-2V6.667A.667.667 0 0 0 14.333 6h-.666a.667.667 0 0 0-.667.667v1.667h-2a.667.667 0 0 0-.667.666v.667c0 .368.299.667.667.667h2V12c0 .368.299.667.667.667h.666A.667.667 0 0 0 15 12v-1.666h2a.667.667 0 0 0 .667-.667V9A.667.667 0 0 0 17 8.334"
		/>
	</svg>
)
export default SvgCartPlus
