import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNewspaper = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M23 4H3.667a1 1 0 0 0-1 1v.333H1a1 1 0 0 0-1 1v11.334A2.333 2.333 0 0 0 2.333 20H22a2 2 0 0 0 2-2V5a1 1 0 0 0-1-1M2.333 18A.333.333 0 0 1 2 17.667V7.333h.667v10.334a.333.333 0 0 1-.334.333m9.834-.667H5.833a.5.5 0 0 1-.5-.5V16.5a.5.5 0 0 1 .5-.5h6.334a.5.5 0 0 1 .5.5v.333a.5.5 0 0 1-.5.5m8.666 0H14.5a.5.5 0 0 1-.5-.5V16.5a.5.5 0 0 1 .5-.5h6.333a.5.5 0 0 1 .5.5v.333a.5.5 0 0 1-.5.5m-8.666-4H5.833a.5.5 0 0 1-.5-.5V12.5a.5.5 0 0 1 .5-.5h6.334a.5.5 0 0 1 .5.5v.333a.5.5 0 0 1-.5.5m8.666 0H14.5a.5.5 0 0 1-.5-.5V12.5a.5.5 0 0 1 .5-.5h6.333a.5.5 0 0 1 .5.5v.333a.5.5 0 0 1-.5.5m0-4h-15a.5.5 0 0 1-.5-.5V7.167a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5v1.666a.5.5 0 0 1-.5.5"
		/>
	</svg>
)
export default SvgNewspaper
