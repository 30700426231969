import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMicrophoneOff = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_5419_54537"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" />
		</mask>
		<g mask="url(#mask0_5419_54537)">
			<path
				d="M17.7504 14.95L16.3004 13.5C16.5337 13.1167 16.7087 12.7167 16.8254 12.3C16.9421 11.8833 17.0004 11.45 17.0004 11H19.0004C19.0004 11.7333 18.8921 12.4292 18.6754 13.0875C18.4587 13.7458 18.1504 14.3667 17.7504 14.95ZM14.8004 11.95L9.00039 6.15V5C9.00039 4.16667 9.29206 3.45833 9.87539 2.875C10.4587 2.29167 11.1671 2 12.0004 2C12.8337 2 13.5421 2.29167 14.1254 2.875C14.7087 3.45833 15.0004 4.16667 15.0004 5V11C15.0004 11.1833 14.9796 11.35 14.9379 11.5C14.8962 11.65 14.8504 11.8 14.8004 11.95ZM11.0004 21V17.9C9.26706 17.6667 7.83372 16.8958 6.70039 15.5875C5.56706 14.2792 5.00039 12.75 5.00039 11H7.00039C7.00039 12.3833 7.48789 13.5625 8.46289 14.5375C9.43789 15.5125 10.6171 16 12.0004 16C12.5671 16 13.1046 15.9125 13.6129 15.7375C14.1212 15.5625 14.5837 15.3167 15.0004 15L16.4254 16.425C15.9421 16.8083 15.4129 17.1292 14.8379 17.3875C14.2629 17.6458 13.6504 17.8167 13.0004 17.9V21H11.0004ZM19.8004 22.6L1.40039 4.2L2.80039 2.8L21.2004 21.2L19.8004 22.6Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgMicrophoneOff
