import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDropdown = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 12 8" {...props}>
		<path
			fill="currentColor"
			d="M10.293.293 6 4.586 1.707.293.293 1.707 6 7.414l5.707-5.707z"
		/>
	</svg>
)
export default SvgDropdown
