import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M4.99997 19H6.2615L16.4981 8.76343L15.2366 7.50191L4.99997 17.7385V19ZM3.5 20.5V17.1154L16.6904 3.93081C16.8416 3.79346 17.0086 3.68732 17.1913 3.61241C17.374 3.53749 17.5656 3.50003 17.7661 3.50003C17.9666 3.50003 18.1608 3.53561 18.3488 3.60678C18.5368 3.67793 18.7032 3.79106 18.848 3.94618L20.0692 5.18271C20.2243 5.32757 20.3349 5.49427 20.4009 5.68281C20.4669 5.87132 20.5 6.05984 20.5 6.24836C20.5 6.44944 20.4656 6.64134 20.3969 6.82406C20.3283 7.00679 20.219 7.17377 20.0692 7.32498L6.88458 20.5H3.5ZM15.8563 8.14373L15.2366 7.50191L16.4981 8.76343L15.8563 8.14373Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgEdit
