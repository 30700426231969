import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowLeft= (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M9.95168 17.6538L4.29785 12L9.95168 6.34616L11.0055 7.43076L7.18625 11.25H19.7017V12.75H7.18625L11.0055 16.5692L9.95168 17.6538Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgArrowLeft
