import * as React from 'react'
import type { SVGProps } from 'react'
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M22.902 6.503A2.86 2.86 0 0 0 20.89 4.48C19.117 4 12 4 12 4s-7.116 0-8.89.479a2.86 2.86 0 0 0-2.012 2.024C.622 8.29.622 12.016.622 12.016s0 3.727.476 5.513A2.82 2.82 0 0 0 3.11 19.52C4.884 20 12 20 12 20s7.116 0 8.89-.479a2.82 2.82 0 0 0 2.012-1.992c.476-1.786.476-5.513.476-5.513s0-3.726-.476-5.513M9.672 15.4V8.633l5.948 3.383z"
		/>
	</svg>
)
export default SvgYoutube
