import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowUpRight = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M13.219 9.8381H9.49568C9.31887 9.8381 9.1493 9.76786 9.02428 9.64284C8.89926 9.51781 8.82902 9.34824 8.82902 9.17143C8.82902 8.99462 8.89926 8.82505 9.02428 8.70003C9.1493 8.575 9.31887 8.50476 9.49568 8.50476H14.829C14.9166 8.5045 15.0034 8.52156 15.0845 8.55497C15.1655 8.58838 15.2391 8.63748 15.301 8.69944C15.363 8.7614 15.4121 8.83499 15.4455 8.916C15.4789 8.997 15.4959 9.08381 15.4957 9.17143V14.5048C15.4957 14.6816 15.4254 14.8511 15.3004 14.9762C15.1754 15.1012 15.0058 15.1714 14.829 15.1714C14.6522 15.1714 14.4826 15.1012 14.3576 14.9762C14.2326 14.8511 14.1624 14.6816 14.1624 14.5048V10.7808L9.64302 15.3001C9.51792 15.4251 9.3483 15.4953 9.17145 15.4952C8.9946 15.4952 8.82502 15.4249 8.70002 15.2998C8.57501 15.1747 8.50482 15.005 8.50488 14.8282C8.50495 14.6513 8.57526 14.4818 8.70035 14.3568L13.219 9.8381Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgArrowUpRight
