import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSchedule = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M15.4731 16.5269L16.5269 15.4731L12.75 11.6959V7.00001H11.25V12.3039L15.4731 16.5269ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5766 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45271 3.248 8.29658C3.74667 7.14043 4.42342 6.13475 5.27825 5.27953C6.1331 4.4243 7.13834 3.74724 8.29398 3.24836C9.44959 2.74947 10.6844 2.50003 11.9983 2.50003C13.3122 2.50003 14.5473 2.74936 15.7034 3.24803C16.8596 3.7467 17.8652 4.42345 18.7205 5.27828C19.5757 6.13313 20.2527 7.13837 20.7516 8.29401C21.2505 9.44962 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8653 18.7217 18.7205C17.8669 19.5757 16.8616 20.2528 15.706 20.7517C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2166 20 16.1041 19.2208 17.6625 17.6625C19.2208 16.1042 20 14.2167 20 12C20 9.78334 19.2208 7.89584 17.6625 6.33751C16.1041 4.77917 14.2166 4.00001 12 4.00001C9.78331 4.00001 7.89581 4.77917 6.33748 6.33751C4.77914 7.89584 3.99998 9.78334 3.99998 12C3.99998 14.2167 4.77914 16.1042 6.33748 17.6625C7.89581 19.2208 9.78331 20 12 20Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgSchedule
