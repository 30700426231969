import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHeartFilled = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M11.8003 5.21366C9.55838 2.92878 5.91553 2.92878 3.67364 5.21366C1.44212 7.48794 1.44212 11.1676 3.67364 13.4419L10.5724 20.4729C11.3564 21.2719 12.6436 21.2719 13.4276 20.4729L20.3264 13.4419C22.5579 11.1676 22.5579 7.48794 20.3264 5.21366C18.0845 2.92878 14.4416 2.92878 12.1997 5.21366C12.09 5.32544 11.91 5.32544 11.8003 5.21366Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgHeartFilled
