import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVideocallOff = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_5419_54536"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" />
		</mask>
		<g mask="url(#mask0_5419_54536)">
			<path
				d="M22.0004 17.5L18.0004 13.5V15.175L6.82539 3.99999H16.0004C16.5504 3.99999 17.0212 4.19582 17.4129 4.58749C17.8046 4.97915 18.0004 5.44999 18.0004 5.99999V10.5L22.0004 6.49999V17.5ZM20.5504 23.35L0.650391 3.44999L2.05039 2.04999L21.9504 21.95L20.5504 23.35ZM4.00039 3.99999L18.0004 18C18.0004 18.55 17.8046 19.0208 17.4129 19.4125C17.0212 19.8042 16.5504 20 16.0004 20H4.00039C3.45039 20 2.97956 19.8042 2.58789 19.4125C2.19622 19.0208 2.00039 18.55 2.00039 18V5.99999C2.00039 5.44999 2.19622 4.97915 2.58789 4.58749C2.97956 4.19582 3.45039 3.99999 4.00039 3.99999Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgVideocallOff
