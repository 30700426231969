import { Paragraph } from '@/components/sharedComponents/Paragraph'
import { cn } from '@/lib/utils'
import { ChangeEventHandler, ComponentPropsWithoutRef, ElementRef, forwardRef, useState } from 'react'

type textAreaInstrinsicAttributes = ComponentPropsWithoutRef<'textarea'> & Record<`data-${string}`, string>

export interface ITextarea extends textAreaInstrinsicAttributes  {
	error? : string
	label? : string
	defaultValue?: string
}

export const Textarea = forwardRef<ElementRef<'textarea'>, ITextarea> (
	({ 
	className, 
	id = 'textarea', 
	label = 'test label',
	error,
	...props }, 
	ref) => {
	const [value, setValue] = useState<null | string>( typeof props.children === "string" || typeof props.defaultValue === 'string' ? (props.children?? props.defaultValue) as string : null)
	const [focus, setFocus] = useState<boolean>(false)

	const labelIsUp = (value && value?.length > 0) || focus

	const changeValue: ChangeEventHandler<HTMLTextAreaElement> = e => {
		if(props.onChange){
			props.onChange(e)
		}
		setValue(e.target.value)
	}

	return (
		<div className={cn('relative flex-col rounded-lg transition-all ease-in flex items-center', props.disabled ? 'opacity-70' : undefined, className)}>
			<textarea
				ref={ref}
				className={cn(
					!labelIsUp
						? 'placeholder:text-white'
						: 'placeholder:text-grey-3',
					'peer p-4 text-grey-1 border-box  rounded-lg  border border-solid  border-grey-3  focus-within:outline  focus-within:outline-grey-3  focus-within:outline-2 block relative h-full w-full',
					error ? 'border-error' : null
				)}
				onChange={changeValue}
				onFocus={(e) => {
					if (props.onFocus){
						props.onFocus(e)
					}
					setFocus(true)
				}
				}
				onBlur={(e) => {
					if (props.onBlur){
						props.onBlur(e)
					}
					setFocus(false)
				}}
				id={id}
				name={id}
				rows={props.rows}
				cols={props.cols}
				placeholder="default placeholder"
				{...props}
			/>
			<label
				htmlFor={id}
				className={`
				absolute 
				${labelIsUp ? '-translate-y-4 px-2 py-1 translate-x-[2px]' : 'translate-y-4'} 
				text-grey-2
				left-0 ml-1
				translate-x-3
				bg-white
				text-sm 
				duration-100 
				ease-linear 
				`}
			>
				{label}
			</label>
			<div className='flex w-full justify-between content-between'>
			{typeof error === 'string' ? 
				<Paragraph color='text-error' className='mt-2' id={`error-${label}`}>
					{error}
				</Paragraph> :
				<div/>
			}
			{props.maxLength && 
				<span className='text-grey-3' role='progressbar' aria-valuemax={props.maxLength} aria-valuemin={props.minLength??0} aria-valuenow={value?.length??0}>
					{value?.length??0} / {props.maxLength}
				</span>
			}
			</div>
		</div>
	)
})

Textarea.displayName = 'textArea'
