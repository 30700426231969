import { ILoader } from './ILoader';
import { cn } from "@/lib/utils"

const scaleSizeToPx = {
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28
}

  const animateDash = {
    1: 'animate-dash-1',
    2: 'animate-dash-2',
    3: 'animate-dash-3',
    4: 'animate-dash-4',
    5: 'animate-dash-5',
    6: 'animate-dash-6',
    7: 'animate-dash-7'
  }


function extractNumberFromSize(inputString: string): 1|2|3|4|5|6|7 {
  // Define the regular expression to match the number after "size-"
  const regex = /size-(\d+)/;

  // Try to match the regular expression with the input string
  const match = inputString.match(regex);

  // Check if a match is found and retrieve the number
  if (match && match[1]) {
    // Convert the number from string to number type
    const number = parseInt(match[1], 10);
    
    // Return the extracted number
    return number as 1|2|3|4|5|6|7;
  } else {
    // If no match is found, return null or handle the case according to your needs
    return 5;
  }
}



export default function Loader(
  {
    size = "size-4", 
    color = "inherit", 
    'data-testid' : dataTestId ='occipital-loader',
    className,
    ...props
  }
    :ILoader) {
    
  const extractTailwindSize = extractNumberFromSize(size)
  const sizeOnPx = scaleSizeToPx[extractTailwindSize]
  const offsetCircle = sizeOnPx * 3
  const stroke = Math.floor(sizeOnPx / 10) === 0 ? 1 : Math.floor(sizeOnPx / 10)

  return (
    <svg 
      data-testid={dataTestId}
      width={`${sizeOnPx}px`}
      height={`${sizeOnPx}px`}
      viewBox={`0 0 ${sizeOnPx} ${sizeOnPx}`}
      xmlns="http://www.w3.org/2000/svg"
      focusable={false}
      aria-hidden
      role="status"
      className={cn('animate-rotator', className)}
      {...props}>
    <circle
      className={`${animateDash[extractTailwindSize]} ${color}`}
      strokeDasharray={offsetCircle}
      strokeDashoffset={0}
      style={{ transformOrigin: 'center'}}
      stroke='grey'
      r={sizeOnPx / 2 - stroke / 2 }
      cx={`50%`}
      cy={`50%`}
      fill="none"
      strokeWidth={stroke}
      strokeLinecap="round"
      />

  </svg>
);
}