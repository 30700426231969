const Link = () => {
  return (
    <svg viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.168 15.166C12.669 15.8358 13.3082 16.39 14.0423 16.7911C14.7763 17.1921 15.5881 17.4306 16.4224 17.4904C17.2567 17.5501 18.0942 17.4297 18.8779 17.1374C19.6616 16.845 20.3733 16.3876 20.9646 15.796L24.4646 12.296C25.5272 11.1958 26.1152 9.72228 26.1019 8.1928C26.0886 6.66332 25.4751 5.20025 24.3936 4.1187C23.312 3.03715 21.849 2.42367 20.3195 2.41038C18.79 2.39709 17.3165 2.98505 16.2163 4.04764L14.2096 6.04264"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8287 12.8354C16.3277 12.1656 15.6884 11.6113 14.9544 11.2103C14.2203 10.8092 13.4086 10.5708 12.5743 10.511C11.7399 10.4512 10.9025 10.5716 10.1188 10.864C9.33506 11.1563 8.62338 11.6138 8.03202 12.2054L4.53202 15.7054C3.46943 16.8056 2.88146 18.2791 2.89475 19.8086C2.90804 21.338 3.52153 22.8011 4.60308 23.8827C5.68462 24.9642 7.1477 25.5777 8.67718 25.591C10.2067 25.6043 11.6802 25.0163 12.7804 23.9537L14.7754 21.9587"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Link