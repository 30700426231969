import { IText, Text } from '../Text'

const Paragraph = ({
	children,
	...props
}: Omit<IText,'tag'>) => {

	return (
		<Text tag='p' {...props}>
			{children}
		</Text>
		
	)
}

export default Paragraph
