import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStethoscope = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M13.5 22C11.7 22 10.1667 21.3667 8.9 20.1C7.63333 18.8333 7 17.3 7 15.5V14.925C5.56667 14.6917 4.375 14.0208 3.425 12.9125C2.475 11.8042 2 10.5 2 9V3H5V2H7V6H5V5H4V9C4 10.1 4.39167 11.0417 5.175 11.825C5.95833 12.6083 6.9 13 8 13C9.1 13 10.0417 12.6083 10.825 11.825C11.6083 11.0417 12 10.1 12 9V5H11V6H9V2H11V3H14V9C14 10.5 13.525 11.8042 12.575 12.9125C11.625 14.0208 10.4333 14.6917 9 14.925V15.5C9 16.75 9.4375 17.8125 10.3125 18.6875C11.1875 19.5625 12.25 20 13.5 20C14.75 20 15.8125 19.5625 16.6875 18.6875C17.5625 17.8125 18 16.75 18 15.5V13.825C17.4167 13.625 16.9375 13.2667 16.5625 12.75C16.1875 12.2333 16 11.65 16 11C16 10.1667 16.2917 9.45833 16.875 8.875C17.4583 8.29167 18.1667 8 19 8C19.8333 8 20.5417 8.29167 21.125 8.875C21.7083 9.45833 22 10.1667 22 11C22 11.65 21.8125 12.2333 21.4375 12.75C21.0625 13.2667 20.5833 13.625 20 13.825V15.5C20 17.3 19.3667 18.8333 18.1 20.1C16.8333 21.3667 15.3 22 13.5 22ZM19 12C19.2833 12 19.5208 11.9042 19.7125 11.7125C19.9042 11.5208 20 11.2833 20 11C20 10.7167 19.9042 10.4792 19.7125 10.2875C19.5208 10.0958 19.2833 10 19 10C18.7167 10 18.4792 10.0958 18.2875 10.2875C18.0958 10.4792 18 10.7167 18 11C18 11.2833 18.0958 11.5208 18.2875 11.7125C18.4792 11.9042 18.7167 12 19 12Z" 
			fill="currentColor"
		/>
	</svg>
)
export default SvgStethoscope
