import { ChangeEventHandler, ReactNode, forwardRef, useState } from 'react'
import { IInput } from './IInput'
import { cn } from '@/lib/utils'
import { Paragraph } from '@/components/sharedComponents/Paragraph'

export function InputContainer({
	children,
	width = 'full',
	disabled,
	className,
	error,
}: {
	children?: ReactNode
	width: IInput['width']
	disabled: IInput['disabled']
	className?: string
	error?: string
}) {
	const sizes = {
		small: 'w-52',
		medium: 'w-72',
		large: 'w-96',
		full: 'w-full',
	}

	return (
		<div
			className={cn(
				'relative bg-white rounded transition-all ease-in flex items-center border border-solid  border-grey-3 shadow-input',
				disabled ? 'opacity-50' : 'opacity-100',
				sizes[width],
				className,
				typeof error === 'string'
					? 'border-error focus-within:outline-error'
					: undefined,
			)}
		>
			{children}
		</div>
	)
}

const Input = forwardRef<HTMLInputElement, IInput>(
	(
		{
			type,
			width = 'full',
			disabled = false,
			required,
			'data-testid': dataTestId = 'occipital-input',
			className,
			classNameContainer,
			error,
			counter,
			...props
		},
		ref,
	) => {
		const [value, setValue] = useState<null | string>(null)
		const [seePass, setSeePass] = useState(false)
		const [characterCounter, setCharacterCounter] = useState(0)

		const changeValue: ChangeEventHandler<HTMLInputElement> = e => {
			setValue(e.target.value)
			// Hacemos un type assertion para indicar que nativeEvent es un InputEvent
			const inputEvent = e.nativeEvent as InputEvent
			if (inputEvent.inputType === 'deleteContentBackward') {
				setCharacterCounter((prev) => prev > 0 ? prev-1 : 0)
			} else {
				setCharacterCounter((prev) => prev + 1)
			}
			props.onChange && props.onChange(e)
		}

		return (
			<div className={cn('mt-6 flex flex-col', classNameContainer)}>
				<InputContainer
					className={cn(props.classNameInputContainer)}
					width={width}
					disabled={disabled}
					error={error}
				>
					<input
						data-testid={dataTestId}
						aria-invalid={!!error}
						aria-describedby={
							error ? `error-${props.label}` : undefined
						}
						ref={ref}
						id={props.id ?? props.label}
						required={required}
						type={
							type === 'password'
								? (seePass === false && 'password') || 'text'
								: type
						}
						disabled={disabled}
						className={cn(
							'bg-white focus:outline-none rounded z-0 px-3 peer h-12 w-full disabled:cursor-not-allowed focus:placeholder:text-grey-4',
							value?.length !== 0 ? 'text-grey-2' : undefined,
							props.label
								? 'placeholder:text-transparent'
								: 'placeolder:text-grey-4',
							className,
						)}
						placeholder=""
						value={value ?? undefined}
						style={{
							// Oculta las flechas en navegadores WebKit
							appearance: 'none',
							// Oculta las flechas en Firefox
							MozAppearance: 'textfield',
						}}
						maxLength={counter?.max}
						{...props}
						onChange={changeValue}
					/>
					{props.label && (
						<label
							htmlFor={props.id ?? props.label}
							className={cn(
								`
				absolute 
				text-grey-2
				left-0 ml-2
				-translate-y-3 
				bg-white
				px-1
				text-sm 
				duration-100 
				ease-linear 
				peer-placeholder-shown:translate-y-0 
				peer-placeholder-shown:text-base 
				peer-placeholder-shown:text-grey-2
				peer-focus:z-0
				peer-focus:-translate-y-6 
				peer-focus:px-1
				peer-focus:text-sm`,
								value?.length !== 0
									? '-translate-y-6 z-0'
									: undefined,
							)}
						>
							{props.label}
							{required ? <span aria-hidden>*</span> : null}
						</label>
					)}
					{props.measureUnit && (
						<p
							className={cn(
								disabled ? 'cursor-default' : 'cursor-pointer',
								'right-0 mr-3 peer-focus:text-grey-1',
								value ? 'text-grey-1' : 'text-grey-3',
							)}
							aria-label={`unidad de medida ${props.measureUnit}`}
						>
							{props.measureUnit}
						</p>
					)}
					{type === 'password' && (
						<p
							className={cn(
								disabled ? 'cursor-default' : 'cursor-pointer',
								'right-0 mr-3 text-primary',
							)}
							onClick={() => setSeePass(!seePass)}
						>
							{seePass ? 'ocultar' : 'mostrar'}
						</p>
					)}
				</InputContainer>
				{counter?.show || error ? (
					<div
						className={cn(
							'flex',
							counter?.show
								? error
									? 'justify-between gap-1'
									: 'justify-end'
								: 'justify-normal'
						)}
					>
						{typeof error === 'string' && (
							<Paragraph
								color="text-error"
								className='mt-1'
								id={`error-${props.label}`}
							>
								{' '}
								{error}
							</Paragraph>
						)}
						{counter?.show && (
							<span className='text-grey-400 text-xxs md:text-sm self-start mt-1'>
								{characterCounter}/{counter?.max}
							</span>
						)}
					</div>
				) : null}
			</div>
		)
	},
)
Input.displayName = 'Input'

export { Input }
