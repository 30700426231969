import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStarFill = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22" {...props}>
		<path
			fill="currentColor"
			d="m10.804 1.075-2.72 5.517-6.088.887c-1.092.158-1.53 1.504-.738 2.275l4.404 4.292-1.041 6.062c-.188 1.096.966 1.917 1.933 1.404L12 18.65l5.446 2.863c.966.508 2.12-.309 1.933-1.405l-1.042-6.062 4.405-4.292c.791-.77.354-2.117-.738-2.275l-6.087-.887-2.721-5.517c-.488-.983-1.9-.996-2.392 0"
		/>
	</svg>
)
export default SvgStarFill
