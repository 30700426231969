import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import { RefCallBack } from 'react-hook-form'
import './inputPhoneStyles.css'
import 'react-phone-input-2/lib/material.css'
import { InputHTMLAttributes, useEffect, useState } from 'react'
import { cn } from '@/lib/utils'

export interface IPhoneInput extends PhoneInputProps {
  ref?: RefCallBack
  classNameContainer?: string
  error?: string
  inputProps?: InputHTMLAttributes<HTMLInputElement>
}

/** 
 * - Por defecto, el usuario puede ingresar 10 digitos para el numero. Esto es modificable con la prop **enableLongNumbers**<br>
 * - Con la prop **enableAreaCodes** podemos activar codigos de area provinciales para las provincias/estados de cada pais.
 * Sin embargo, si la combinamos con **countryCodeEditable** (la cual prohibe la edicion del prefijo internacional y/o el codigo de area local)
 * nos encontraremos con un bug (ultima version 2.15.1) que no permite continuar escribiendo el numero de telefono.<br>
 * - Utilizar dentro de un **Controller** de react-form-hook, de igual forma que los Select<br>
 * - Libreria utilizada para el input: https://www.npmjs.com/package/react-phone-input-2
 */
export function InputPhone ({
    classNameContainer,
    copyNumbersOnly = true,
    country = 'ar',
    countryCodeEditable = false,
    enableSearch = true,
    enableAreaCodes = false,
    enableTerritories = true,
    error,
    inputProps,
    inputClass,
    onChange,
    preferredCountries = ['ar', 'cl', 'uy', 'mx', 've', 'pe', 'py', 'co'],
    ref,
    searchNotFound = 'No encontramos resultados',
    searchPlaceholder = 'Buscar',
    specialLabel = 'Teléfono',
    value,
    ...props
  }: IPhoneInput) {
    const [width, setWidth] = useState(280)
    
    useEffect(() => {
      if (typeof document !== 'undefined') {
        const parentDiv = document?.getElementsByClassName('telInput')?.[0] as HTMLDivElement
        setWidth(parentDiv?.offsetWidth - 1)
      }
    }, [])

    return (
      <PhoneInput
        country={country}
        value={value}
        onChange={onChange}
        inputProps={{
          name: inputProps?.name ?? 'ws',
          required: inputProps?.required ?? true,
          autoFocus: inputProps?.autoFocus ?? true,
          ref,
          ...inputProps
        }}
        isValid={() => error || true}
        countryCodeEditable={countryCodeEditable}
        enableSearch={enableSearch}
        enableAreaCodes={enableAreaCodes}
        enableTerritories={enableTerritories}
        copyNumbersOnly={copyNumbersOnly}
        preferredCountries={preferredCountries}
        containerClass={cn(
          'mt-6',
          'telInput',
          error ? `mb-12 min-[515px]:mb-6` : '',
          classNameContainer,
        )}
        inputClass={cn(
          '!w-full text-grey-2 !border-grey-3 !py-3 !rounded',
          inputClass,
        )}
        dropdownStyle={{
          width: `${width}px`
        }}
        searchNotFound={searchNotFound}
        searchPlaceholder={searchPlaceholder}
        specialLabel={specialLabel}
        {...props}
      />
    )
  }
