import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path fill="currentColor" d="M11 15h2V9h3l-4-5-4 5h3z" />
		<path
			fill="currentColor"
			d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2z"
		/>
	</svg>
)
export default SvgUpload
