import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLungs = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M23.854 17.03a30.8 30.8 0 0 0-3.825-8.663C19.199 7.071 18.676 6 17.027 6 15.576 6 14.4 7.103 14.4 8.464v2.254l-1.233-.822a.6.6 0 0 1-.267-.499V3a.6.6 0 0 0-.6-.6h-.6a.6.6 0 0 0-.6.6v6.397a.6.6 0 0 1-.267.5l-1.233.821V8.464C9.6 7.104 8.424 6 6.973 6c-1.65 0-2.172 1.07-3.002 2.367a30.8 30.8 0 0 0-3.825 8.664A4.3 4.3 0 0 0 0 18.14c0 2.297 2.343 3.955 4.697 3.324l2.23-.598c1.582-.424 2.673-1.78 2.673-3.323v-3.281l-3.219 2.146a.3.3 0 0 1-.333 0 .3.3 0 0 1-.083-.083l-.333-.5a.3.3 0 0 1 .083-.415L12 11.22l6.285 4.19a.3.3 0 0 1 .083.415l-.333.5a.3.3 0 0 1-.416.083L14.4 14.263v3.28c0 1.544 1.09 2.9 2.672 3.324l2.232.598c2.353.63 4.696-1.027 4.696-3.323q0-.564-.146-1.111"
		/>
	</svg>
)
export default SvgLungs
