import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path fill="#fff" d="M0 0h24v24H0z" />
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.5 2.5a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8.94h-3.4a2 2 0 0 1-2-2V2.5z"
			clipRule="evenodd"
		/>
		<path fill="currentColor" d="M15 7.975h4.5L15 2.5z" />
		<path
			fill="#FCFCFC"
			d="M14.753 15.059v-4.087h2.57v.702H15.59v.997h1.614v.701H15.59v1.687zM10.42 15.059v-4.087h1.49q.972 0 1.507.534.534.527.534 1.507t-.534 1.513-1.507.533zm.837-.736h.605q1.215 0 1.215-1.31 0-1.304-1.215-1.304h-.605zM6.685 15.059v-4.087h1.738q.638 0 .983.348.346.342.346.957t-.346.962-.983.348h-.901v1.472zm.837-2.168h.761q.664 0 .664-.614 0-.603-.664-.603h-.761z"
		/>
	</svg>
)
export default SvgPdf
