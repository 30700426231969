import * as React from 'react'
import type { SVGProps } from 'react'
const SvgListUl = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M11 6.99999H2V8.99999H11V6.99999ZM11 15H2V17H11V15ZM16.34 11L12.8 7.45999L14.21 6.04999L16.33 8.16999L20.57 3.92999L22 5.33999L16.34 11ZM16.34 19L12.8 15.46L14.21 14.05L16.33 16.17L20.57 11.93L22 13.34L16.34 19Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgListUl
