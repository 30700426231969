import { forwardRef, ReactNode } from "react"
import * as Tabs from '@radix-ui/react-tabs'
import { cn } from '@/lib/utils'

interface TabsContainerProps {
  children?: ReactNode
  className?: string
}


function TabsContainer({ children, className }: TabsContainerProps) {
  return (
    <div
      className={cn(
        `relative rounded-lg transition-all ease-in flex items-center`,
        className
      )}
    >
      {children}
    </div>
  )
}

const TabsRoot = ({ children, className, ...props }: Tabs.TabsProps) => {
  return (
    <Tabs.Root className={cn("relative", className)} {...props}>
      {children}
    </Tabs.Root>
  )
}

const TabsList = forwardRef<HTMLDivElement, Tabs.TabsListProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Tabs.List
        ref={ref}
        className={cn(
          "flex space-x-1 rounded-lg p-1",
          className
        )}
        {...props}
      >
        {children}
      </Tabs.List>
    )
  }
)

TabsList.displayName = 'TabsList'

const TabsTrigger = forwardRef<HTMLButtonElement, Tabs.TabsTriggerProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Tabs.Trigger
        ref={ref}
        className={cn(
          "group relative hover:text-secondary data-[state=active]:text-secondary data-[state=active]:font-semibold disabled:pointer-events-none disabled:text-gray-500",
          "px-3 py-1.5 text-secondary",
          "radix-state-active:bg-white radix-state-active:shadow",
          className
        )}
        {...props}
      >
        {children}
        <span className={cn("absolute bottom-0 left-2 w-0 h-0.5 bg-secondary transition-all duration-300 group-hover:w-8 group-data-[state=active]:w-8", className)}></span>
      </Tabs.Trigger>
    )
  }
)

TabsTrigger.displayName = 'TabsTrigger'

const TabsContent = forwardRef<HTMLDivElement, Tabs.TabsContentProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Tabs.Content
        ref={ref}
        className={cn(
          "p-4 bg-white rounded-lg focus:outline-none",
          className
        )}
        {...props}
      >
        {children}
      </Tabs.Content>
    )
  }
)

TabsContent.displayName = 'TabsContent'
/**
 * This component is used for conditional rendering of content based on the selected tab
 */
export {
  TabsRoot,
  TabsList,
  TabsTrigger,
  TabsContent,
  TabsContainer
}
