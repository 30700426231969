import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEventUpcoming = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_5350_38853"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_5350_38853)">
			<path
				d="M15.0288 21.5V20H18.6923C18.7693 20 18.8398 19.9679 18.9038 19.9037C18.9679 19.8397 19 19.7692 19 19.6922V10.3077H5V14.25H3.5V6.30773C3.5 5.80257 3.675 5.37498 4.025 5.02498C4.375 4.67498 4.80258 4.49998 5.30775 4.49998H6.69225V2.38474H8.23075V4.49998H15.8077V2.38474H17.3078V4.49998H18.6923C19.1974 4.49998 19.625 4.67498 19.975 5.02498C20.325 5.37498 20.5 5.80257 20.5 6.30773V19.6922C20.5 20.1974 20.325 20.625 19.975 20.975C19.625 21.325 19.1974 21.5 18.6923 21.5H15.0288ZM8 23.6442L6.95575 22.6L9.77125 19.75H1.25V18.25H9.77125L6.95575 15.4L8 14.3557L12.6442 19L8 23.6442ZM5 8.80773H19V6.30773C19 6.23073 18.9679 6.16023 18.9038 6.09623C18.8398 6.03207 18.7693 5.99998 18.6923 5.99998H5.30775C5.23075 5.99998 5.16025 6.03207 5.09625 6.09623C5.03208 6.16023 5 6.23073 5 6.30773V8.80773Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgEventUpcoming
