import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M13.8461 17.6538L12.7923 16.5692L16.6116 12.75H4.5V11.25H16.6116L12.7923 7.43076L13.8461 6.34616L19.5 12L13.8461 17.6538Z" 
			fill="currentColor"
		/>
	</svg>
)
export default SvgArrowRight
