import { es } from 'date-fns/locale'
import { DayPicker, DayPickerRangeProps } from 'react-day-picker'
import './Calendar.css'
import { cn } from '@/lib/utils'

/**
 * DayPicker render a date picker component to let users pick dates from a
 * calendar. See http://react-day-picker.js.org for updated documentation and
 * examples.
 *
 * ### Customization
 *
 * DayPicker offers different customization props. For example,
 *
 * - show multiple months using `numberOfMonths`
 * - display a dropdown to navigate the months via `captionLayout`
 * - display the week numbers with `showWeekNumbers`
 * - disable or hide days with `disabled` or `hidden`
 *
 * ### Controlling the months
 *
 * Change the initially displayed month using the `defaultMonth` prop. The
 * displayed months are controlled by DayPicker and stored in its internal
 * state. To control the months yourself, use `month` instead of `defaultMonth`
 * and use the `onMonthChange` event to set it.
 *
 * To limit the months the user can navigate to, use
 * `fromDate`/`fromMonth`/`fromYear` or `toDate`/`toMonth`/`toYear`.
 *
 * ### Selection modes
 *
 * DayPicker supports different selection mode that can be toggled using the
 * `mode` prop:
 *
 * - `mode="single"`: only one day can be selected. Use `required` to make the
 *   selection required. Use the `onSelect` event handler to get the selected
 *   days.
 * - `mode="multiple"`: users can select one or more days. Limit the amount of
 *   days that can be selected with the `min` or the `max` props.
 * - `mode="range"`: users can select a range of days. Limit the amount of days
 *   in the range with the `min` or the `max` props.
 * - `mode="default"` (default): the built-in selections are disabled. Implement
 *   your own selection mode with `onDayClick`.
 *
 * The selection modes should cover the most common use cases. In case you
 * need a more refined way of selecting days, use `mode="default"`. Use the
 * `selected` props and add the day event handlers to add/remove days from the
 * selection.
 *
 * ### Modifiers
 *
 * A _modifier_ represents different styles or states for the days displayed in
 * the calendar (like "selected" or "disabled"). Define custom modifiers using
 * the `modifiers` prop.
 *
 * ### Formatters and custom component
 *
 * You can customize how the content is displayed in the date picker by using
 * either the formatters or replacing the internal components.
 *
 * For the most common cases you want to use the `formatters` prop to change how
 * the content is formatted in the calendar. Use the `components` prop to
 * replace the internal components, like the navigation icons.
 *
 * ### Styling
 *
 * DayPicker comes with a default, basic style in `react-day-picker/style` – use
 * it as template for your own style.
 *
 * If you are using CSS modules, pass the imported styles object the
 * `classNames` props.
 *
 * You can also style the elements via inline styles using the `styles` prop.
 *
 * ### Form fields
 *
 * If you need to bind the date picker to a form field, you can use the
 * `useInput` hooks for a basic behavior. See the `useInput` source as an
 * example to bind the date picker with form fields.
 *
 * ### Localization
 *
 * To localize DayPicker, import the locale from `date-fns` package and use the
 * `locale` prop.
 *
 * For example, to use Spanish locale:
 *
 * ```
 * import { es } from 'date-fns/locale';
 * <DayPicker locale={es} />
 * ```
 */
export function CalendarRange({
	locale = es,
	mode = 'range',
	captionLayout = 'buttons',
	showOutsideDays = true,
	classNames,
	...dayPickerProps
}: DayPickerRangeProps) {
	return (
		<>
			<DayPicker
				locale={locale}
				// className={cn("rounded-2xl bg-white max-w-[637px]", className)}
				classNames={{
					...classNames,
					cell: cn(
						'min-w-[91px] min-h-[94px] h-[94px] w-[91px] bg-[#FEFEFF] align-top text-grey-1 border border-solid border-grey-5',
						classNames?.cell,
					),
					button: cn('px-3 py-2.5 w-full h-full', classNames?.button),
					button_reset: cn(
						'px-3 py-2.5 w-full h-full',
						classNames?.button_reset,
					),
					day: cn('w-full h-full flex', classNames?.day),
					day_range_start: cn(
						'rounded-none',
						classNames?.day_range_start,
					),
					day_range_end: cn(
						'rounded-none',
						classNames?.day_range_end,
					),
					day_selected: cn('bg-primary/20', classNames?.day_selected),
					day_outside: cn(
						'bg-grey-6 border border-solid text-grey-4',
						classNames?.day_outside,
					),
					months: cn('bg-white rounded-2xl', classNames?.months),
				}}
				mode={mode}
				captionLayout={captionLayout}
				showOutsideDays={showOutsideDays}
				{...dayPickerProps}
			/>
		</>
	)
}
