import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSparking = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M15 6L16.8906 11.1094L22 13L16.8906 14.8906L15 20L13.1094 14.8906L8 13L13.1094 11.1094L15 6Z"
			fill="currentColor"
		/>
		<path
			d="M6.5 3L7.71541 6.28459L11 7.5L7.71541 8.71541L6.5 12L5.28459 8.71541L2 7.5L5.28459 6.28459L6.5 3Z"
			fill="currentColor"
		/>
		<path
			d="M6 15L6.81027 17.1897L9 18L6.81027 18.8103L6 21L5.18973 18.8103L3 18L5.18973 17.1897L6 15Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgSparking
