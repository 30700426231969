import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFolderOpen = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_3654_40083"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_3654_40083)">
			<path
				d="M4.25 19.5C3.7641 19.5 3.35096 19.3298 3.01058 18.9894C2.67019 18.649 2.5 18.2359 2.5 17.75V6.30773C2.5 5.82183 2.67981 5.39907 3.03943 5.03946C3.39904 4.67984 3.8218 4.50003 4.3077 4.50003H9.79802L11.798 6.50003H19.6923C20.1397 6.50003 20.5256 6.64458 20.8499 6.93368C21.1743 7.22278 21.3621 7.57822 21.4134 8.00001H11.1846L9.1846 6.00001H4.3077C4.21795 6.00001 4.14423 6.02886 4.08653 6.08656C4.02883 6.14426 3.99998 6.21798 3.99998 6.30773V17.6923C3.99998 17.7628 4.01761 17.8205 4.05287 17.8654C4.08812 17.9102 4.1346 17.9487 4.1923 17.9808L6.52497 10.1923H23.1827L20.7615 18.2577C20.6474 18.6333 20.4336 18.9343 20.1202 19.1606C19.8067 19.3868 19.459 19.5 19.0769 19.5H4.25ZM5.7634 18H19.2692L21.1558 11.6923H7.64995L5.7634 18Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgFolderOpen
