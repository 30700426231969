import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowDown= (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M11.9995 15.0538L6.3457 9.39999L7.39953 8.34616L11.9995 12.9462L16.5995 8.34616L17.6534 9.39999L11.9995 15.0538Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgArrowDown
