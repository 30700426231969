import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDiagnosis = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_1779_12368"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_1779_12368)">
			<path
				d="M8.25 18.3654H15.75V16.8654H8.25V18.3654ZM8.25 15.5577H15.75V14.0577H8.25V15.5577ZM12 12.2904C13.0615 11.3288 13.9519 10.5119 14.6711 9.83946C15.3903 9.16702 15.75 8.41542 15.75 7.58466C15.75 7.04876 15.5589 6.58978 15.1769 6.20773C14.7948 5.82568 14.3359 5.63466 13.8 5.63466C13.3987 5.63466 13.0564 5.72152 12.7731 5.89526C12.4897 6.06897 12.232 6.30007 12 6.58853C11.7679 6.30007 11.5102 6.06897 11.2269 5.89526C10.9436 5.72152 10.6013 5.63466 10.2 5.63466C9.66407 5.63466 9.20511 5.82568 8.82308 6.20773C8.44103 6.58978 8.25 7.04876 8.25 7.58466C8.25 8.41542 8.59711 9.15452 9.29132 9.80196C9.98556 10.4494 10.8884 11.2788 12 12.2904ZM17.6922 21.5H6.3077C5.80257 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V4.30773C4.5 3.8026 4.675 3.37503 5.025 3.02503C5.375 2.67503 5.80257 2.50003 6.3077 2.50003H17.6922C18.1974 2.50003 18.625 2.67503 18.975 3.02503C19.325 3.37503 19.5 3.8026 19.5 4.30773V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6922 21.5ZM6.3077 20H17.6922C17.782 20 17.8557 19.9712 17.9134 19.9135C17.9711 19.8558 18 19.782 18 19.6923V4.30773C18 4.21798 17.9711 4.14426 17.9134 4.08656C17.8557 4.02886 17.782 4.00001 17.6922 4.00001H6.3077C6.21795 4.00001 6.14423 4.02886 6.08652 4.08656C6.02882 4.14426 5.99997 4.21798 5.99997 4.30773V19.6923C5.99997 19.782 6.02882 19.8558 6.08652 19.9135C6.14423 19.9712 6.21795 20 6.3077 20Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgDiagnosis
