import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 22 24"
		fill="currentColor"
		{...props}
	>
		<g clipPath="url(#filter_svg__a)">
			<path fill="currentColor" d="M10 18h4v-2h-4zM3 6v2h18V6zm3 7h12v-2H6z" />
		</g>
		<defs>
			<clipPath id="filter_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
)
export default SvgFilter
