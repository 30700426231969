import * as React from 'react'
import type { SVGProps } from 'react'
const SvgStatMinus = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 15.375L6 9.37501L7.4 7.97501L12 12.55L16.6 7.97501L18 9.37501L12 15.375Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgStatMinus
