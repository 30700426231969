import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserOutline = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M12 11.692q-1.444 0-2.472-1.028T8.5 8.192 9.528 5.72 12 4.692t2.472 1.028T15.5 8.192t-1.028 2.472T12 11.692m-7.5 7.616v-2.223q0-.735.399-1.36a2.66 2.66 0 0 1 1.066-.963 14.5 14.5 0 0 1 2.992-1.09 12.95 12.95 0 0 1 6.086 0q1.509.363 2.992 1.09.667.336 1.066.962t.399 1.36v2.224zm1.5-1.5h12v-.723a1 1 0 0 0-.176-.563 1.33 1.33 0 0 0-.478-.422 13 13 0 0 0-2.635-.964 11.4 11.4 0 0 0-5.422 0q-1.343.327-2.635.964-.301.164-.478.422a1 1 0 0 0-.176.563zm6-7.616q.825 0 1.412-.587Q14 9.017 14 8.192t-.588-1.412A1.93 1.93 0 0 0 12 6.192q-.825 0-1.412.588A1.93 1.93 0 0 0 10 8.192q0 .825.588 1.413.587.587 1.412.587"
		/>
	</svg>
)
export default SvgUserOutline
