import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cn } from '@/lib/utils';
import { IAvatar } from './IAvatar';

/**
 * Avatar component to display a user's profile picture with optional fallback text.
 * Supports different sizes and allows custom styling via className.
 *
 * @component
 * @param {IAvatar} props - Props for the Avatar component.
 * @returns {JSX.Element} The rendered Avatar component.
 *
 * @example
 * // Example usage of the Avatar component
 * <Avatar
 *   src="https://via.placeholder.com/150"
 *   alt="User Avatar"
 *   fallback="JD"
 *   size="large"
 * />
 */

export const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, IAvatar>(
  ({ src, alt, fallback = 'LM', className, size = 'medium', ...props }, ref) => {
    return (
      <AvatarPrimitive.Root
        ref={ref}
        className={cn(
          'inline-flex items-center justify-center overflow-hidden rounded-full bg-gray-200',
          size === 'small' && 'h-8 w-8',
          size === 'medium' && 'h-12 w-12',
          size === 'large' && 'h-16 w-16',
          className
        )}
        {...props}
      >
        <AvatarPrimitive.Image
          className="object-cover w-full h-full"
          src={src}
          alt={alt}
        />
        <AvatarPrimitive.Fallback
          className={cn(
            'flex items-center justify-center text-gray-500 font-medium',
            size === 'large' ? 'text-lg' : size === 'medium' ? 'text-m' : 'text-s'
          )}
          delayMs={600}
        >
          {fallback}
        </AvatarPrimitive.Fallback>
      </AvatarPrimitive.Root>
    );
  }
);

Avatar.displayName = 'Avatar';
