import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_2919_15262"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_2919_15262)">
			<path
				d="M12 17.1154C13.1474 17.1154 14.1202 16.7163 14.9182 15.9183C15.7163 15.1202 16.1153 14.1474 16.1153 13C16.1153 11.8526 15.7163 10.8798 14.9182 10.0818C14.1202 9.28369 13.1474 8.88466 12 8.88466C10.8525 8.88466 9.87979 9.28369 9.08173 10.0818C8.28366 10.8798 7.88462 11.8526 7.88462 13C7.88462 14.1474 8.28366 15.1202 9.08173 15.9183C9.87979 16.7163 10.8525 17.1154 12 17.1154ZM12 15.6154C11.2615 15.6154 10.641 15.3641 10.1384 14.8616C9.63586 14.359 9.38458 13.7385 9.38458 13C9.38458 12.2615 9.63586 11.641 10.1384 11.1385C10.641 10.6359 11.2615 10.3846 12 10.3846C12.7384 10.3846 13.359 10.6359 13.8615 11.1385C14.3641 11.641 14.6154 12.2615 14.6154 13C14.6154 13.7385 14.3641 14.359 13.8615 14.8616C13.359 15.3641 12.7384 15.6154 12 15.6154ZM4.3077 20.5C3.80257 20.5 3.375 20.325 3.025 19.975C2.675 19.625 2.5 19.1974 2.5 18.6923V7.30773C2.5 6.8026 2.675 6.37503 3.025 6.02503C3.375 5.67503 3.80257 5.50003 4.3077 5.50003H7.36153L9.21153 3.50003H14.7884L16.6384 5.50003H19.6923C20.1974 5.50003 20.625 5.67503 20.975 6.02503C21.325 6.37503 21.5 6.8026 21.5 7.30773V18.6923C21.5 19.1974 21.325 19.625 20.975 19.975C20.625 20.325 20.1974 20.5 19.6923 20.5H4.3077Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgCamera
