import { type ClassValue, clsx } from "clsx"
import { twMerge  } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * @typedef {Object} DateObject
 * @property {number} [day] - The day of the month. Defaults to today if not provided.
 * @property {number} [month] - The month of the year (1-12). Defaults to the current month if not provided.
 * @property {number} [year] - The year. Defaults to the current year if not provided.
 */


/**
 * Converts an array of date objects to an array of JavaScript Date objects.
 * If no parameters are provided for a date object, it defaults to the current date.
 * @param {DateObject[]} dateObjects - Array of objects containing day, month, and year.
 * @returns {Date[]} Array of JavaScript Date objects.
 */
export function convertToDates(dateObjects: { day?: number, month?: number, year?: number }[] = []): Date[] {
  const today = new Date();
  return dateObjects.map(dateObj => {
    const day = dateObj.day ?? today.getDate();
    const month = dateObj.month ? dateObj.month - 1 : today.getMonth(); // month is 0-based in JavaScript Date
    const year = dateObj.year ?? today.getFullYear();
    return new Date(year, month, day);
  });
}