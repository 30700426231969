import React, { forwardRef } from 'react'
import Link from 'next/link'
import { IButtonIcon } from './IButtonIcon'
import { Loader } from '../Loader'
import { Icon } from '../../clientComponents/Icon'
import { cn } from '@/lib/utils'

/** Content of button when is Loading, for internal use only */
function ButtonContentOnLoading() {
	return <div className='flex content-center justify-center items-center'>
		<Loader size='size-5' aria-label='Cargando...' />
	</div>
}

const ButtonIcon = forwardRef<HTMLButtonElement | HTMLAnchorElement, IButtonIcon>(function Button({
    disabled,
    loading,
    variant='filled',
    'data-testid': dataTestId ='occipital-button',
    icon='book',
    className,
    action,
    ...props
}, ref) {



    const filledBackground =  `${loading || disabled ? 'bg-grey-3' : 'bg-primary'}`
    const text = loading || disabled ? 'text-grey-3' : 'text-primary'
    const border = loading || disabled ? 'border-grey-3' : 'border-primary'
    const commonStyles = cn('size-5', 'rounded-full', 'px-4','min-w-12', 'h-12', 'items-center', 'justify-center', 'flex', ' focus-visible:outline focus-visible:outline-grey-1 focus-visible:outline-2 transition-all', 'text-m')
    
    const buttonStyles = {
        filled: cn(commonStyles, filledBackground, 'text-grey-6', className),
        outlined: cn(commonStyles, 'bg-white', text, border, 'border border-solid', className),
        text: cn(commonStyles, 'bg-none', text, className)
    }

    if (props.type === 'link'){
        return  <Link 
            ref={ref as React.MutableRefObject<HTMLAnchorElement>}
            className={cn(buttonStyles[variant])}
            aria-disabled={disabled || loading}
            data-testid={dataTestId}
            onClick={action}
            {...props}
        >
            {loading ? 
                <ButtonContentOnLoading /> : 
                 <div className='flex items-center content-center place-content-center shrink-0'>
                    <Icon size='size-5' name={icon}/>
                </div>
            }
        </Link>
    }
        
    return (
        <button
            ref={ref as React.MutableRefObject<HTMLButtonElement>}
            className={cn(buttonStyles[variant])}
            aria-disabled={disabled}
            disabled={disabled || loading}
            onClick={action}
            {...props}
        >
            {loading ? 
                <ButtonContentOnLoading/> : 
                <div className='flex items-center content-center place-content-center shrink-0'>
                    <Icon size='size-5' name={icon} aria-hidden/>
                </div>
            }
        </button>
    )
})

export default ButtonIcon;
