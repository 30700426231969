import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMoney = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M22.8 4.8H1.2A1.2 1.2 0 0 0 0 6v12a1.2 1.2 0 0 0 1.2 1.2h21.6A1.2 1.2 0 0 0 24 18V6a1.2 1.2 0 0 0-1.2-1.2m-21 12.6V15a2.4 2.4 0 0 1 2.4 2.4zm0-8.4V6.6h2.4A2.4 2.4 0 0 1 1.8 9M12 15.6c-1.657 0-3-1.612-3-3.6s1.343-3.6 3-3.6 3 1.612 3 3.6-1.344 3.6-3 3.6m10.2 1.8h-2.4a2.4 2.4 0 0 1 2.4-2.4zm0-8.4a2.4 2.4 0 0 1-2.4-2.4h2.4z"
		/>
	</svg>
)
export default SvgMoney
