import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNotificationOn = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.75 17C9.16421 17 9.5 17.3358 9.5 17.75V18.5C9.5 19.0967 9.73705 19.669 10.159 20.091C10.581 20.5129 11.1533 20.75 11.75 20.75C12.3467 20.75 12.919 20.5129 13.341 20.091C13.7629 19.669 14 19.0967 14 18.5V17.75C14 17.3358 14.3358 17 14.75 17C15.1642 17 15.5 17.3358 15.5 17.75V18.5C15.5 19.4946 15.1049 20.4484 14.4016 21.1516C13.6984 21.8549 12.7446 22.25 11.75 22.25C10.7554 22.25 9.80161 21.8549 9.09835 21.1516C8.39509 20.4484 8 19.4946 8 18.5V17.75C8 17.3358 8.33579 17 8.75 17Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.0155 1C10.5583 1 9.5745 2.14942 9.23905 3.27601L9.23416 3.28141C9.22777 3.28803 9.22022 3.29442 9.2127 3.29961C9.20571 3.30443 9.20151 3.30631 9.20151 3.30631C8.35287 3.65578 7.35362 4.21281 6.57263 5.23399C5.78549 6.26324 5.27406 7.69281 5.27399 9.68828C5.27281 11.6763 5.06254 12.7666 4.75335 13.5151C4.48226 14.1714 4.12606 14.6029 3.61933 15.2166C3.54526 15.3063 3.46797 15.4 3.38726 15.4987C2.44548 16.6513 3.34235 18.25 4.77852 18.25H19.2573C20.6961 18.25 21.5779 16.6429 20.6438 15.4987C20.5633 15.4001 20.4861 15.3067 20.4121 15.2171L20.4119 15.2168C19.9051 14.603 19.549 14.1716 19.2781 13.5153C19.0834 13.0434 18.928 12.4357 18.8398 11.5527C18.3949 11.7836 17.9012 11.9333 17.3782 11.9823C17.4823 12.8386 17.6543 13.5125 17.8916 14.0876C18.2543 14.9665 18.7611 15.5773 19.2639 16.1833L19.2642 16.1837C19.3371 16.2715 19.41 16.3593 19.4823 16.4478C19.5292 16.5055 19.533 16.5652 19.5025 16.6254C19.4741 16.6814 19.4058 16.75 19.2573 16.75H4.77852C4.62437 16.75 4.55497 16.6791 4.5271 16.6245C4.51104 16.593 4.50624 16.5625 4.50855 16.5369C4.51059 16.5144 4.51899 16.4843 4.54884 16.4478C4.62121 16.3592 4.69413 16.2714 4.76712 16.1834L4.76732 16.1832C5.27006 15.5772 5.77676 14.9666 6.13974 14.0878C6.55698 13.0776 6.77279 11.7617 6.77399 9.68828C6.77399 7.96069 7.21129 6.86809 7.76413 6.14523C8.32314 5.41428 9.05577 4.98856 9.77223 4.6935C10.1541 4.53643 10.5351 4.18648 10.6727 3.71761L10.6732 3.71574C10.8867 2.98152 11.4381 2.5 12.0155 2.5C12.593 2.5 13.1446 2.98116 13.3608 3.7169L13.3614 3.7188C13.4982 4.17986 13.8751 4.53471 14.2613 4.69353C14.3622 4.73508 14.4635 4.77927 14.5646 4.82658C15.0495 4.45392 15.6222 4.18988 16.2464 4.07089C15.7693 3.7331 15.28 3.4908 14.8325 3.30656C14.8325 3.30656 14.8287 3.30467 14.8231 3.30075C14.8157 3.29559 14.8078 3.28895 14.8009 3.28172C14.798 3.27868 14.7957 3.27603 14.794 3.27394C14.456 2.14947 13.4732 1 12.0155 1Z"
			fill="currentColor"
		/>
		<rect
			x="14"
			y="5"
			width="6"
			height="6"
			rx="3"
			className="text-error fill-current"
		/>
	</svg>
)
export default SvgNotificationOn
