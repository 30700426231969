import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M16.3748 3.24984C17.5342 3.25331 18.6451 3.71539 19.4648 4.53517C20.2846 5.35495 20.7467 6.46582 20.7502 7.62516V16.3748C20.7467 17.5342 20.2846 18.6451 19.4648 19.4648C18.6451 20.2846 17.5342 20.7467 16.3748 20.7502H7.62516C6.46582 20.7467 5.35495 20.2846 4.53517 19.4648C3.71539 18.6451 3.25331 17.5342 3.24984 16.3748V7.62516C3.25331 6.46582 3.71539 5.35495 4.53517 4.53517C5.35495 3.71539 6.46582 3.25331 7.62516 3.24984H16.3748ZM16.3748 1.5H7.62516C4.25625 1.5 1.5 4.25625 1.5 7.62516V16.3748C1.5 19.7437 4.25625 22.5 7.62516 22.5H16.3748C19.7437 22.5 22.5 19.7437 22.5 16.3748V7.62516C22.5 4.25625 19.7437 1.5 16.3748 1.5Z"
			fill="currentColor"
		/>
		<path
			d="M17.6875 7.625C17.4279 7.625 17.1742 7.54802 16.9583 7.4038C16.7425 7.25959 16.5742 7.0546 16.4749 6.81477C16.3756 6.57494 16.3496 6.31104 16.4002 6.05644C16.4509 5.80184 16.5759 5.56798 16.7594 5.38442C16.943 5.20087 17.1768 5.07586 17.4314 5.02522C17.686 4.97458 17.9499 5.00057 18.1898 5.09991C18.4296 5.19925 18.6346 5.36748 18.7788 5.58331C18.923 5.79915 19 6.05291 19 6.3125C19.0004 6.48496 18.9667 6.6558 18.9008 6.81521C18.835 6.97462 18.7384 7.11945 18.6164 7.24141C18.4945 7.36336 18.3496 7.46002 18.1902 7.52585C18.0308 7.59168 17.86 7.62537 17.6875 7.625Z"
			fill="currentColor"
		/>
		<path
			d="M12 8.49984C12.6923 8.49984 13.369 8.70512 13.9446 9.08973C14.5202 9.47433 14.9688 10.021 15.2337 10.6605C15.4986 11.3001 15.568 12.0039 15.4329 12.6828C15.2978 13.3618 14.9645 13.9855 14.475 14.475C13.9855 14.9645 13.3618 15.2978 12.6828 15.4329C12.0039 15.568 11.3001 15.4986 10.6606 15.2337C10.021 14.9688 9.47433 14.5202 9.08973 13.9446C8.70513 13.369 8.49985 12.6923 8.49985 12C8.50084 11.072 8.86992 10.1823 9.52611 9.52611C10.1823 8.86992 11.072 8.50084 12 8.49984ZM12 6.75C10.9617 6.75 9.94662 7.05791 9.08326 7.63478C8.2199 8.21166 7.54699 9.0316 7.14963 9.99091C6.75227 10.9502 6.64831 12.0058 6.85088 13.0242C7.05345 14.0426 7.55347 14.9781 8.28769 15.7123C9.02192 16.4465 9.95738 16.9466 10.9758 17.1491C11.9942 17.3517 13.0498 17.2477 14.0091 16.8504C14.9684 16.453 15.7883 15.7801 16.3652 14.9167C16.9421 14.0534 17.25 13.0384 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgInstagram
