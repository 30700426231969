import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M12 12a6 6 0 1 0 0-12 6 6 0 1 0 0 12m4.2 1.5h-.783a8.17 8.17 0 0 1-6.834 0H7.8a6.3 6.3 0 0 0-6.3 6.3v1.95A2.25 2.25 0 0 0 3.75 24h16.5a2.25 2.25 0 0 0 2.25-2.25V19.8c0-3.478-2.822-6.3-6.3-6.3"
		/>
	</svg>
)
export default SvgUser
