import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPng = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path fill="#fff" d="M0 0h24v24H0z" />
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.5 2.5a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h10.999a2 2 0 0 0 2-2V8.94h-3.4a2 2 0 0 1-2-2V2.5z"
			clipRule="evenodd"
		/>
		<path fill="currentColor" d="M15.001 7.975h4.5L15 2.5z" />
		<path
			fill="#FCFCFC"
			d="M16.221 15.123q-.642 0-1.09-.261a1.7 1.7 0 0 1-.675-.73q-.232-.47-.232-1.102 0-.648.237-1.124.243-.475.697-.736t1.09-.261q.4 0 .756.121.362.123.589.325l-.27.701a1.6 1.6 0 0 0-.508-.278 1.8 1.8 0 0 0-.561-.087q-.578 0-.87.348-.285.342-.286.991 0 .655.292.992.297.336.875.336.307 0 .648-.105v-.8h-.821v-.649h1.512v2.006a2.5 2.5 0 0 1-.648.232q-.367.08-.735.08M10.121 15.06v-4.087h.621l1.9 2.602v-2.602h.778v4.086h-.615l-1.906-2.608v2.608zM6.386 15.06v-4.087h1.738q.638 0 .983.347.346.342.346.957t-.346.962-.983.348h-.901v1.472zm.837-2.169h.761q.664 0 .664-.614 0-.603-.664-.603h-.761z"
		/>
	</svg>
)
export default SvgPng
