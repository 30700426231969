import * as React from 'react'
import type { SVGProps } from 'react'
const SvgThumbsDownFill = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M0 2.625v11.25C0 14.496.504 15 1.125 15h3.75C5.496 15 6 14.496 6 13.875V2.625C6 2.004 5.496 1.5 4.875 1.5h-3.75C.504 1.5 0 2.004 0 2.625M1.875 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0m12.75 12c-.946 0-1.382-1.842-1.59-2.71-.245-1.015-.497-2.065-1.19-2.76-1.523-1.525-2.321-3.467-4.178-5.302a.56.56 0 0 1-.167-.4V2.808c0-.307.246-.557.552-.563.742-.013 1.72-.425 2.468-.758C12.01.825 13.861.001 16.125 0h.133c2.005 0 4.377.02 5.333 1.394.394.565.49 1.267.288 2.092.765.8 1.175 2.29.768 3.504.823 1.099.898 2.631.437 3.725l.005.005c.557.56.915 1.466.911 2.307-.007 1.422-1.226 2.723-2.791 2.723H16.44c.343 1.328 1.56 2.444 1.56 4.432C18 23.625 15.75 24 14.625 24"
		/>
	</svg>
)
export default SvgThumbsDownFill
