import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNurse = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M16.472 15 12 19.472 7.528 15c-3.351.146-6.028 2.888-6.028 6.272A2.73 2.73 0 0 0 4.229 24H19.77a2.73 2.73 0 0 0 2.729-2.728c0-3.384-2.677-6.126-6.028-6.272M12 14.25a6 6 0 0 0 6-6V3.086a1.5 1.5 0 0 0-.973-1.407L13.053.191a3 3 0 0 0-2.106 0l-3.974 1.49A1.5 1.5 0 0 0 6 3.086V8.25a6 6 0 0 0 6 6M10.125 3.36a.235.235 0 0 1 .234-.235h1.016V2.11a.234.234 0 0 1 .235-.235h.78a.235.235 0 0 1 .235.235v1.015h1.016a.235.235 0 0 1 .234.235v.78a.235.235 0 0 1-.234.235h-1.016v1.016a.234.234 0 0 1-.235.234h-.78a.235.235 0 0 1-.235-.234V4.375H10.36a.235.235 0 0 1-.234-.234zM8.25 7.5h7.5v.75a3.75 3.75 0 1 1-7.5 0z"
		/>
	</svg>
)
export default SvgNurse
