import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLabProfile = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M8 12V10H16V12H8ZM8 8V6H16V8H8ZM6 14H13.5C13.9833 14 14.4333 14.104 14.85 14.312C15.2667 14.5207 15.6167 14.8167 15.9 15.2L18 17.95V4H6V14ZM6 20H17.05L14.325 16.425C14.225 16.2917 14.1043 16.1877 13.963 16.113C13.821 16.0377 13.6667 16 13.5 16H6V20ZM18 22H6C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.97933 2.19567 5.45 2 6 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V20C20 20.55 19.8043 21.021 19.413 21.413C19.021 21.8043 18.55 22 18 22Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgLabProfile
