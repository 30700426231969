import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDiversity = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M1.546 13.367A9 9 0 0 1 .77 11.5 7 7 0 0 1 .5 9.6q0-2.57 1.765-4.335T6.6 3.5q1.545 0 2.957.725A7.3 7.3 0 0 1 12 6.28a7.3 7.3 0 0 1 2.443-2.054A6.4 6.4 0 0 1 17.4 3.5q2.57 0 4.335 1.765Q23.5 7.031 23.5 9.6q0 .93-.27 1.87a9.6 9.6 0 0 1-.776 1.888 3 3 0 0 0-.51-.61 2.5 2.5 0 0 0-.66-.436q.351-.71.533-1.38T22 9.6q0-1.941-1.33-3.27Q19.341 5 17.4 5q-1.785 0-2.959 1.021Q13.268 7.041 12 8.6q-1.268-1.572-2.441-2.587Q8.384 5 6.6 5 4.659 5 3.33 6.33 2 7.659 2 9.6q0 .681.183 1.356t.523 1.365a2.7 2.7 0 0 0-.654.441q-.288.265-.506.605M.5 21.52V20.3q0-1.014 1.046-1.647 1.046-.634 2.714-.634.306 0 .586.018t.537.067a3.64 3.64 0 0 0-.575 2.005v1.41zm6 0v-1.375q0-1.432 1.525-2.298 1.524-.865 3.978-.865 2.476 0 3.986.865 1.511.866 1.511 2.298v1.375zm12.692 0v-1.41q0-.565-.133-1.064a3.6 3.6 0 0 0-.401-.941q.255-.05.53-.067a9 9 0 0 1 .562-.018q1.687 0 2.719.634 1.03.633 1.031 1.647v1.22zM12 18.481q-1.56 0-2.679.413-1.12.414-1.243 1.02v.105h7.861v-.105q-.138-.606-1.246-1.02-1.107-.413-2.693-.413m-7.74-1.433q-.705 0-1.208-.503a1.65 1.65 0 0 1-.503-1.208q0-.715.503-1.21a1.66 1.66 0 0 1 1.209-.492q.715 0 1.213.493.498.494.498 1.209 0 .705-.498 1.208a1.64 1.64 0 0 1-1.213.503m15.491 0q-.7 0-1.206-.503a1.64 1.64 0 0 1-.506-1.208q.001-.715.506-1.21a1.67 1.67 0 0 1 1.208-.492q.723 0 1.216.493.495.494.494 1.209 0 .705-.493 1.208a1.64 1.64 0 0 1-1.219.503m-7.747-.817a2.5 2.5 0 0 1-1.84-.757 2.5 2.5 0 0 1-.76-1.84q0-1.102.758-1.85A2.52 2.52 0 0 1 12 11.04q1.103 0 1.85.745.746.746.746 1.847a2.53 2.53 0 0 1-.745 1.84q-.745.76-1.848.76M12 12.538q-.454 0-.775.316a1.05 1.05 0 0 0-.321.78q0 .455.321.776.321.32.78.32t.775-.32a1.07 1.07 0 0 0 .316-.78q0-.459-.315-.775T12 12.539"
		/>
	</svg>
)
export default SvgDiversity
