import * as React from 'react'
import type { SVGProps } from 'react'
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M5.5 15a3.5 3.5 0 0 0 2.36-.93l6.26 3.58c-.08.276-.12.563-.12.85a3.53 3.53 0 1 0 1.14-2.57l-6.26-3.58a2.7 2.7 0 0 0 .12-.76l6.15-3.52A3.49 3.49 0 1 0 14 5.5c.004.287.044.573.12.85L8.43 9.6A3.5 3.5 0 1 0 5.5 15m12 2a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0-13a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m-12 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3"
		/>
	</svg>
)
export default SvgShare
