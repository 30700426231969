import type { SVGProps } from 'react'

const ChatBubble = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9.8 16.4008C9.8 20.0459 12.7549 23.0008 16.4 23.0008C17.7885 23.0008 19.0769 22.572 20.14 21.8396C20.4541 21.6232 22.5085 23.0469 22.78 22.7808C23.0418 22.5241 21.5223 20.5792 21.7387 20.2821C22.532 19.1928 23 17.8515 23 16.4008C23 12.7557 20.0451 9.80078 16.4 9.80078C12.7549 9.80078 9.8 12.7557 9.8 16.4008Z"
			fill="white"
			stroke="currentColor"
			strokeWidth="1.25"
		/>
		<path
			d="M17.5 9.25C17.5 13.8063 13.8063 17.5 9.25 17.5C7.51431 17.5 5.90381 16.964 4.575 16.0485C4.1824 15.778 1.61439 17.5577 1.275 17.225C0.947729 16.9042 2.84708 14.473 2.57666 14.1017C1.58504 12.7401 1 11.0633 1 9.25C1 4.69365 4.69365 1 9.25 1C13.8063 1 17.5 4.69365 17.5 9.25Z"
			fill="white"
			stroke="currentColor"
			strokeWidth="1.25"
		/>
		<path
			d="M8.78448 11.7082V11.6577C8.79009 11.1221 8.84621 10.6958 8.95284 10.3789C9.05948 10.062 9.21101 9.80545 9.40744 9.60914C9.60386 9.41284 9.83958 9.23196 10.1146 9.06651C10.2801 8.96555 10.4289 8.84637 10.5608 8.70895C10.6926 8.56874 10.7965 8.40749 10.8722 8.22521C10.9508 8.04292 10.9901 7.84101 10.9901 7.61947C10.9901 7.34465 10.9255 7.10628 10.7965 6.90436C10.6674 6.70245 10.4948 6.54681 10.2787 6.43744C10.0627 6.32807 9.82274 6.27339 9.55897 6.27339C9.32886 6.27339 9.10718 6.32106 8.89392 6.41641C8.68065 6.51176 8.50246 6.66179 8.35935 6.86651C8.21624 7.07122 8.13346 7.33904 8.11101 7.66995H7.05029C7.07274 7.19321 7.19621 6.78518 7.4207 6.44586C7.648 6.10653 7.94685 5.84713 8.31726 5.66765C8.69047 5.48818 9.10437 5.39844 9.55897 5.39844C10.0528 5.39844 10.4822 5.49659 10.847 5.69289C11.2146 5.8892 11.498 6.15841 11.6972 6.50054C11.8993 6.84267 12.0003 7.23247 12.0003 7.66995C12.0003 7.97842 11.9526 8.25746 11.8572 8.50704C11.7646 8.75663 11.6299 8.97957 11.4531 9.17588C11.2791 9.37218 11.0687 9.54605 10.8217 9.69748C10.5748 9.85172 10.377 10.0144 10.2282 10.1854C10.0795 10.3537 9.97147 10.5542 9.90412 10.787C9.83677 11.0197 9.80029 11.31 9.79468 11.6577V11.7082H8.78448ZM9.32325 14.1984C9.1156 14.1984 8.93741 14.1241 8.78869 13.9755C8.63996 13.8269 8.5656 13.6488 8.5656 13.4413C8.5656 13.2337 8.63996 13.0557 8.78869 12.907C8.93741 12.7584 9.1156 12.6841 9.32325 12.6841C9.53091 12.6841 9.70909 12.7584 9.85782 12.907C10.0065 13.0557 10.0809 13.2337 10.0809 13.4413C10.0809 13.5787 10.0458 13.7049 9.97568 13.8199C9.90833 13.9348 9.81713 14.0274 9.70208 14.0975C9.58983 14.1648 9.46356 14.1984 9.32325 14.1984Z"
			fill="currentColor"
		/>
	</svg>
)

export default ChatBubble
