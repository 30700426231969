import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLoyalty = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_3654_40086"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_3654_40086)">
			<path
				d="M13 17.2115L16.2692 13.8846C16.4333 13.7205 16.5615 13.5279 16.6538 13.3067C16.7461 13.0856 16.7923 12.8462 16.7923 12.5885C16.7923 12.0923 16.6163 11.6651 16.2644 11.3068C15.9125 10.9484 15.4884 10.7693 14.9923 10.7693C14.6692 10.7693 14.3519 10.8577 14.0404 11.0346C13.7288 11.2116 13.382 11.5167 13 11.95C12.5769 11.4962 12.2173 11.1859 11.9211 11.0193C11.625 10.8526 11.3205 10.7693 11.0077 10.7693C10.5115 10.7693 10.0875 10.9484 9.73558 11.3068C9.38366 11.6651 9.2077 12.0923 9.2077 12.5885C9.2077 12.8462 9.25385 13.0856 9.34615 13.3067C9.43845 13.5279 9.56665 13.7205 9.73075 13.8846L13 17.2115ZM13.9038 21.0538C13.6166 21.341 13.2602 21.4846 12.8346 21.4846C12.409 21.4846 12.0526 21.341 11.7654 21.0538L2.96537 12.2538C2.82051 12.109 2.70673 11.942 2.62405 11.7529C2.54135 11.5638 2.5 11.3628 2.5 11.15V4.00001C2.5 3.59104 2.64776 3.23881 2.94328 2.94331C3.23878 2.64779 3.59101 2.50003 3.99998 2.50003H11.15C11.3564 2.50003 11.5541 2.53978 11.7432 2.61928C11.9323 2.69876 12.0961 2.80773 12.2346 2.94618L21.0346 11.7616C21.3282 12.0552 21.4766 12.4138 21.4798 12.8375C21.483 13.2612 21.341 13.6166 21.0538 13.9038L13.9038 21.0538ZM12.8346 20L19.9846 12.85L11.15 4.00001H3.99998V11.15L12.8346 20ZM6.49998 7.74998C6.84613 7.74998 7.14099 7.62819 7.38458 7.38461C7.62816 7.14102 7.74995 6.84616 7.74995 6.50001C7.74995 6.15386 7.62816 5.85899 7.38458 5.61541C7.14099 5.37182 6.84613 5.25003 6.49998 5.25003C6.15383 5.25003 5.85896 5.37182 5.61538 5.61541C5.37179 5.85899 5.25 6.15386 5.25 6.50001C5.25 6.84616 5.37179 7.14102 5.61538 7.38461C5.85896 7.62819 6.15383 7.74998 6.49998 7.74998Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgLoyalty
