import * as React from 'react'
import type { SVGProps } from 'react'
const SvgGraduateCap = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.0651 8.19177C16.1994 8.13254 16.3133 8.03522 16.3928 7.91187C16.4723 7.78852 16.5139 7.64456 16.5123 7.49782C16.5108 7.35108 16.4663 7.20801 16.3843 7.08633C16.3022 6.96465 16.1863 6.86971 16.0508 6.81327L9.62259 3.88527C9.42717 3.79613 9.21488 3.75 9.00009 3.75C8.7853 3.75 8.57301 3.79613 8.37759 3.88527L1.95009 6.81027C1.81657 6.86875 1.70298 6.96487 1.62322 7.08688C1.54346 7.20889 1.50098 7.3515 1.50098 7.49727C1.50098 7.64304 1.54346 7.78565 1.62322 7.90766C1.70298 8.02967 1.81657 8.12579 1.95009 8.18427L8.37759 11.1153C8.57301 11.2044 8.7853 11.2505 9.00009 11.2505C9.21488 11.2505 9.42717 11.2044 9.62259 11.1153L16.0651 8.19177Z"
			stroke="currentColor"
			fill='none'
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.5 7.5V12"
			stroke="currentColor"
			fill='none'
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.5 9.375V12C4.5 12.5967 4.97411 13.169 5.81802 13.591C6.66193 14.0129 7.80653 14.25 9 14.25C10.1935 14.25 11.3381 14.0129 12.182 13.591C13.0259 13.169 13.5 12.5967 13.5 12V9.375"
			stroke="currentColor"
			fill='none'
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
export default SvgGraduateCap
