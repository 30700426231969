import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCalendarHeart = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M3 10H21V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H12"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="transparent"
		/>
		<path
			d="M8 2V6"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 2V6"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21.2899 14.7C20.9492 14.3609 20.5155 14.1305 20.0438 14.038C19.5721 13.9454 19.0835 13.9948 18.6399 14.18C18.3399 14.3 18.0699 14.48 17.8399 14.71L17.4999 15.05L17.1499 14.71C16.8103 14.3692 16.3772 14.137 15.9054 14.0426C15.4336 13.9483 14.9445 13.9961 14.4999 14.18C14.1999 14.3 13.9399 14.48 13.7099 14.71C12.7599 15.65 12.7099 17.24 13.9099 18.45L17.4999 22L21.0999 18.45C22.2999 17.24 22.2399 15.65 21.2899 14.71V14.7Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="transparent"
		/>
	</svg>
)
export default SvgCalendarHeart
