import { ReactNode, useState } from 'react'
import Link from 'next/link'
import { Url } from 'next/dist/shared/lib/router/router'
import { cn } from '@/lib/utils'
import { Icon, IIcon } from '../Icon'
import { Text } from '@/components/sharedComponents/Text'
import Badge from '../Badge/Badge'
import Image from 'next/image'
import { ButtonIcon } from '@/components/sharedComponents/ButtonIcon'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'

/**
 * SidebarContainer component.
 *
 * @param {Object} props - Component props.
 * @param {ReactNode} [props.children] - Child nodes.
 * @param {string} [props.className] - Additional class names.
 * @param {'close' | 'open' | 'hidden'} [props.status='close'] - Initial status of the sidebar.
 * @param {string | StaticImport} [props.src] - Source of the image.
 * @returns {JSX.Element}
 */
export function SidebarContainer({
	children,
	className,
	status: initialStatus = 'close',
	src,
	logoVisibility,
	arrowPosition,
}: {
	children?: ReactNode
	className?: string
	status?: 'close' | 'open' | 'hidden'
	src?: string | StaticImport
	logoVisibility: 'showAlways' | 'hideOnClose'
	arrowPosition: 'right' | 'bottom'
}) {
	const [status, setStatus] = useState(initialStatus)

	if (status === 'hidden') {
		return (
			<ButtonIcon
				aria-label="abrir menú"
				icon="bars"
				type="button"
				onClick={() => setStatus('open')}
			/>
		)
	}

	return (
		<nav
			className={cn(
				'p-4 h-screen bg-white flex flex-col justify-start items-center content-center border-r border-solid border-grey-500',
				status === 'open' ? 'w-64' : 'w-[88px]',
				logoVisibility === 'showAlways' && 'border-r border-solid border-gray-100',
			)}
		>
			{src && status === 'open' ? (
				<Image
					src={src}
					alt=""
					className={cn(
						'h-14',
						status === 'open' ? '' : 'invisibility',
					)}
				/>
			) : src && logoVisibility === 'showAlways' && status === 'close' ? (
				<Image src={src} alt="sidebar logo" className={cn(
					'h-3 my-7',
				)} />
			) 
			: (
				<div className="h-14" />
			)
			}
			<ButtonIcon
			className={cn(
				'relative bg-white p-2 self-end w-6 h-6 min-w-6',
				status && arrowPosition === 'right' && 'left-7 bottom-4',
				arrowPosition === 'bottom' && status === 'open' && 'left-0 bottom-0 my-4',
				arrowPosition === 'bottom' && status === 'close' && 'right-3 my-3'
			)}
				icon={status === 'open' ? 'chevronLeft' : 'chevronRight'}
				aria-label="abrir menú"
				type="button"
				variant="text"
				onClick={() => setStatus(status === 'open' ? 'close' : 'open')}
			/>
			<ul
				className={cn(
					'w-full h-full flex flex-col',
					className,
					status === 'close' ? 'group isClosed' : '',
				)}
			>
				{children}
			</ul>
		</nav>
	)
}

/**
 * SidebarElementContainer component.
 *
 * @param {Object} props - Component props.
 * @param {ReactNode} [props.children] - Child nodes.
 * @param {Url} props.href - Link URL.
 * @param {boolean} [props.active] - Whether the element is active.
 * @returns {JSX.Element}
 */
export function SidebarElementContainer({
	children,
	href,
	active,
	className,
}: {
	children?: ReactNode
	href: Url
	active?: boolean
	className?: string
}) {
	return (
		<li className={cn("h-14 w-full mt-2 text-grey-500", className)}>
			<Link
				className={cn(
					'flex align-middle content-center group-[.isClosed]:justify-center justify-start items-center py-4 px-4 hover:bg-primary-25 rounded-2xl',
					active ? 'hover:bg-primary-50 bg-primary-25' : undefined,
				)}
				href={href}
			>
				{children}
			</Link>
		</li>
	)
}

/**
 * SidebarElement component.
 *
 * @param {Object} props - Component props.
 * @param {IIcon['name']} props.name - Icon name.
 * @param {string} props.children - Child nodes.
 * @param {Url} props.href - Link URL.
 * @param {boolean} [props.active] - Whether the element is active.
 * @param {'warning'|'error'|'success'} [props.status] - Badge status.
 * @param {string} [props.badgeText] - Badge text.
 * @returns {JSX.Element}
 */
export function SidebarElement({
	name,
	children,
	href,
	active,
	status,
	badgeText,
	className,
	asChild
}: {
	name: IIcon['name']
	children: string
	href: Url
	active?: boolean
	status?: 'warning' | 'error' | 'success'
	badgeText?: string
	className?: string
	asChild?: ReactNode
}) {

	if (asChild) {
		return asChild
	}
		return (
			<SidebarElementContainer
				className={className}
				href={href}
				active={active}
			>
				<Icon
					className={active ? 'text-primary' : undefined}
					size="size-6"
					name={name}
				/>
				<div className="group-[.isClosed]:hidden flex">
					<Text color="text-grey-1" tag="span" className="mx-2">
						{children}{' '}
					</Text>
					{status && <Badge status={status}>{badgeText}</Badge>}
				</div>
			</SidebarElementContainer>
		)
}
