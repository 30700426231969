import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSyringe = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 20 20" {...props}>
		<path
			fill="currentColor"
			d="m20.678 5.98-1.415 1.413-2.12-2.12-2.122 2.12 3.535 3.536-1.414 1.414-.707-.707L10.07 18H4.414l-2.121 2.12-1.414-1.413 2.12-2.121v-5.657l6.365-6.364-.707-.707 1.414-1.414 3.536 3.535 2.12-2.121-2.12-2.121L15.02.322l5.657 5.657M8.657 12.342l-2.83-2.828-1.413 1.414 2.829 2.828zm2.828-2.828L8.657 6.686 7.243 8.1l2.828 2.828z"
		/>
	</svg>
)
export default SvgSyringe
