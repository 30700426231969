import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNavigateNext = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M12.9457 12L8.3457 7.39999L9.39953 6.34616L15.0534 12L9.39953 17.6538L8.3457 16.6L12.9457 12Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgNavigateNext
