import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M5.308 20.5q-.758 0-1.283-.525a1.75 1.75 0 0 1-.525-1.283V5.308q0-.758.525-1.283T5.308 3.5h6.702V5H5.308a.3.3 0 0 0-.212.096.3.3 0 0 0-.096.212v13.384q0 .116.096.212a.3.3 0 0 0 .212.096h6.702v1.5zm10.923-4.23-1.039-1.085 2.435-2.435h-8.53v-1.5h8.53l-2.435-2.435 1.039-1.084L20.5 12z"
		/>
	</svg>
)
export default SvgLogout
