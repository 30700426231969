import { cn } from '@/lib/utils'
import { ReactNode, useEffect, useRef } from 'react'

export interface IChatList {
	colors?: {
		/** esta toda la regla escrita como parametro porque sino tailwind no sabe updatearse correctamente */
		bgMessageSelf: `[&>li[data-origin=self]]:bg-${string}`
		bgMessageOther: `[&>li[data-origin=other]]:bg-${string}`
		messageSelfTextColor: `[&>li[data-origin=self]]:text-${string}`
		messageOtherTextColor: `[&>li[data-origin=other]]:text-${string}`
	}
	children?: ReactNode
}

export function ChatList({
	colors: initColors,
	children,
}: {
	colors?: {
		/** esta toda la regla escrita como parametro porque sino tailwind no sabe updatearse correctamente */
		bgMessageSelf: `[&>li[data-origin=self]]:bg-${string}`
		bgMessageOther: `[&>li[data-origin=other]]:bg-${string}`
		messageSelfTextColor: `[&>li[data-origin=self]]:text-${string}`
		messageOtherTextColor: `[&>li[data-origin=other]]:text-${string}`
	}
	children?: ReactNode
}) {
	const colors = {
		bgMessageSelf:
			initColors?.bgMessageSelf ??
			'[&>li[data-origin=self]]:bg-primary-800 [&>li[data-origin=self]]:after:border-t-primary-800',
		bgMessageOther:
			initColors?.bgMessageOther ??
			'[&>li[data-origin=other]]:bg-primary-100 [&>li[data-origin=other]]:after:border-t-primary-100',
		messageSelfTextColor:
			initColors?.messageSelfTextColor ??
			'[&>li[data-origin=self]]:text-white',
		messageOtherTextColor:
			initColors?.messageOtherTextColor ??
			'[&>li[data-origin=other]]:text-grey-900',
	} satisfies IChatList['colors']

	const chatRef = useRef<HTMLUListElement>(null)


	useEffect(() => {
		if (chatRef.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}
	}, [children]) // Se vuelve a ejecutar cuando cambian los mensajes

	return (
		<ul
			ref={chatRef}
			className={cn(
				'flex flex-col gap-y-2 w-full bg-grey-25 px-4 py-2 overflow-y-scroll h-full chatStyles [&::-webkit-scrollbar-thumb]:bg-grey-300 [&::-webkit-scrollbar-thumb]:rounded-lg [&::-webkit-scrollbar]:w-1.5',
				colors.bgMessageSelf,
				colors.messageSelfTextColor,
				colors.bgMessageOther,
				colors.messageOtherTextColor,
			)}
		>
			{children}
		</ul>
	)
}
