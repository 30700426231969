import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowFoward = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 8 12" {...props}>
		<path
			fill="currentColor"
			d="M1.707 11.707 7.414 6 1.707.293.293 1.707 4.586 6 .293 10.293z"
		/>
	</svg>
)
export default SvgArrowFoward
