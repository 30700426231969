import * as React from 'react'
import type { SVGProps } from 'react'
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="transparent"
		/>
		<path
			d="M12 16V12"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			fill="transparent"
			strokeLinejoin="round"
		/>
		<path
			d="M12 8H12.01"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			fill="transparent"
			strokeLinejoin="round"
		/>
	</svg>
)
export default SvgInfo
