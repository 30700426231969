import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserMd = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M12 12a6 6 0 1 0 0-12 6 6 0 1 0 0 12m-5.625 7.875c0 .623.502 1.125 1.125 1.125s1.125-.502 1.125-1.125S8.123 18.75 7.5 18.75s-1.125.502-1.125 1.125M16.5 13.528v2.297c1.71.347 3 1.866 3 3.675v1.955a.75.75 0 0 1-.605.736l-1.51.3a.373.373 0 0 1-.44-.296l-.145-.736a.37.37 0 0 1 .295-.44l.905-.183V19.5c0-2.944-4.5-3.052-4.5.09v1.25l.905.183a.38.38 0 0 1 .295.441l-.145.736a.38.38 0 0 1-.44.295l-1.463-.197a.75.75 0 0 1-.647-.745V19.5a3.76 3.76 0 0 1 3-3.675v-2.119c-.103.033-.207.052-.31.09a8.13 8.13 0 0 1-5.38 0 5.2 5.2 0 0 0-1.06-.244v3.825a2.62 2.62 0 0 1 1.875 2.503 2.627 2.627 0 0 1-2.625 2.625A2.627 2.627 0 0 1 4.88 19.88c0-1.186.792-2.18 1.875-2.503v-3.77C3.773 14.11 1.5 16.679 1.5 19.8v2.1c0 1.158.942 2.1 2.1 2.1h16.8c1.158 0 2.1-.942 2.1-2.1v-2.1c0-3.375-2.663-6.108-6-6.272"
		/>
	</svg>
)
export default SvgUserMd
