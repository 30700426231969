import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNotificationFilledOn = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M20.6286 16.4989C20.5508 16.4052 20.4744 16.3114 20.3994 16.2209C19.3682 14.9736 18.7443 14.2208 18.7443 10.6897C18.7443 8.86156 18.3069 7.36156 17.4449 6.23656C16.8093 5.40547 15.9501 4.775 14.8176 4.30906C14.803 4.30096 14.79 4.29032 14.7791 4.27766C14.3718 2.91359 13.2571 2 11.9999 2C10.7427 2 9.62849 2.91359 9.22115 4.27625C9.21027 4.28845 9.19744 4.29875 9.18318 4.30672C6.54036 5.39469 5.25599 7.48203 5.25599 10.6883C5.25599 14.2208 4.63302 14.9736 3.60083 16.2195C3.52583 16.31 3.44943 16.4019 3.37161 16.4975C3.17061 16.7399 3.04326 17.0348 3.00464 17.3473C2.96601 17.6598 3.01772 17.9769 3.15365 18.2609C3.44286 18.8703 4.05927 19.2486 4.76286 19.2486H19.2421C19.9424 19.2486 20.5546 18.8708 20.8447 18.2642C20.9813 17.9801 21.0335 17.6628 20.9952 17.3499C20.9569 17.037 20.8297 16.7417 20.6286 16.4989Z"
			fill="currentColor"
		/>
		<path
			d="M11.9999 22.5C12.6773 22.4995 13.3419 22.3156 13.9232 21.9679C14.5045 21.6202 14.9809 21.1217 15.3018 20.5252C15.3169 20.4966 15.3244 20.4646 15.3235 20.4322C15.3226 20.3999 15.3133 20.3684 15.2966 20.3407C15.2799 20.313 15.2563 20.2901 15.2282 20.2742C15.2 20.2583 15.1682 20.25 15.1359 20.25H8.86492C8.83254 20.2499 8.80068 20.2582 8.77245 20.274C8.74422 20.2899 8.72057 20.3128 8.70382 20.3405C8.68706 20.3682 8.67777 20.3997 8.67684 20.4321C8.67591 20.4645 8.68337 20.4965 8.69851 20.5252C9.01939 21.1216 9.49569 21.6201 10.0769 21.9678C10.6581 22.3155 11.3226 22.4994 11.9999 22.5Z"
			fill="currentColor"
		/>
		<rect
			x="15"
			y="5"
			width="6"
			height="6"
			rx="3"
			className="text-error fill-current"
		/>
	</svg>
)
export default SvgNotificationFilledOn
