import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMoving = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_4186_43995"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_4186_43995)">
			<path
				d="M3.35168 17.6538L2.29785 16.6L7.42475 11.4731C7.96322 10.9411 8.61225 10.6734 9.37185 10.6702C10.1315 10.667 10.7805 10.9346 11.319 11.4731L12.469 12.6231C12.7203 12.8744 13.0171 12.9984 13.3594 12.9952C13.7017 12.992 13.9985 12.868 14.2498 12.6231L19.1325 7.74998H16.2017V6.25003H21.7017V11.75H20.2017V8.81918L15.3036 13.6923C14.7651 14.2244 14.1145 14.492 13.3517 14.4952C12.5889 14.4984 11.9414 14.234 11.4094 13.7019L10.2344 12.5269C9.99334 12.2859 9.70071 12.167 9.35648 12.1702C9.01224 12.1734 8.71961 12.2923 8.47858 12.5269L3.35168 17.6538Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgMoving
