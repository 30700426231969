import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDoneAll = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_6908_91017"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_6908_91017)">
			<path
				d="M6.70023 17.6538L1.39648 12.35L2.46573 11.2963L6.71573 15.5463L7.06173 15.2L8.11573 16.2537L6.70023 17.6538ZM12.3502 17.6538L7.04648 12.35L8.10023 11.2808L12.3502 15.5308L21.5502 6.33075L22.604 7.4L12.3502 17.6538ZM12.004 12.35L10.9347 11.2963L15.8847 6.34625L16.954 7.4L12.004 12.35Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgDoneAll
