import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMagicStar = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M17.2903 4.14001L17.2203 7.93001C17.2103 8.45001 17.5403 9.14001 17.9603 9.45001L20.4403 11.33C22.0303 12.53 21.7703 14 19.8703 14.6L16.6403 15.61C16.1003 15.78 15.5303 16.37 15.3903 16.92L14.6203 19.86C14.0103 22.18 12.4903 22.41 11.2303 20.37L9.47027 17.52C9.15027 17 8.39027 16.61 7.79027 16.64L4.45027 16.81C2.06027 16.93 1.38027 15.55 2.94027 13.73L4.92027 11.43C5.29027 11 5.46027 10.2 5.29027 9.66001L4.27027 6.42001C3.68027 4.52001 4.74027 3.47001 6.63027 4.09001L9.58027 5.06001C10.0803 5.22001 10.8303 5.11001 11.2503 4.80001L14.3303 2.58001C16.0003 1.39001 17.3303 2.09001 17.2903 4.14001Z"
			fill="currentColor"
		/>
		<path
			d="M21.4403 20.47L18.4103 17.44C18.1203 17.15 17.6403 17.15 17.3503 17.44C17.0603 17.73 17.0603 18.21 17.3503 18.5L20.3803 21.53C20.5303 21.68 20.7203 21.75 20.9103 21.75C21.1003 21.75 21.2903 21.68 21.4403 21.53C21.7303 21.24 21.7303 20.76 21.4403 20.47Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgMagicStar
