import * as React from 'react'
import type { SVGProps } from 'react'
const SvgGreenCheckCircle = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 16 16" {...props}>
		<circle cx={8} cy={8} r={7.5} stroke="#259B38" />
		<path
			fill="#259B38"
			d="m11.75 5.917-5 5-2.292-2.292.588-.588 1.704 1.7 4.413-4.408z"
		/>
	</svg>
)
export default SvgGreenCheckCircle
