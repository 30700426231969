import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNavigateBefore = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M13.9995 17.6538L8.3457 12L13.9995 6.34616L15.0534 7.39999L10.4534 12L15.0534 16.6L13.9995 17.6538Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgNavigateBefore
