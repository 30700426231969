import { Text } from '@/components/sharedComponents/Text'
import { ICounterBadge } from './ICounterBadge'
import { cn } from '@/lib/utils'

const CounterBadge = ({
	colorScheme = 'bg-primary',
	amount,
	selected,
	label,
}: ICounterBadge) => {
	let styles = ''
	let selectedStyles = ''

	if (selected) {
		selectedStyles = cn(
			'flex',
			'items-center',
			'gap-[3px]',
			'text-white',
			`${colorScheme}`,
		)
	} else {
		/* Tienen relaciones distintas de contraste asi que definimos cada caso */
		switch (colorScheme) {
			case 'bg-accent':
				styles = 'bg-accent-50 text-accent-400'
				break
			case 'bg-error':
				styles = 'bg-error-50 text-error-400'
				break
			case 'bg-secondary':
				styles = 'bg-secondary-100 text-secondary'
				break
			case 'bg-success':
				styles = 'bg-success-50 text-success'
				break
			case 'bg-tertiary':
				styles = 'bg-tertiary-100 text-tertiary-600'
				break
			case 'bg-warning':
				styles = 'bg-warning-100 text-warning-600'
				break
			default:
				styles = 'bg-primary-50 text-primary'
				break
		}
	}

	return (
		<div
			className={cn(
				'flex items-center gap-2 px-4 py-2 bg-white rounded-lg cursor-pointer',
				selected
					? 'shadow-[-2px_-2px_4px_0_rgb(161,161,161,0.05),2px_2px_4px_0_rgb(161,161,161,0.05)]'
					: '',
			)}
		>
			<Text
				tag="h4"
				color="text-grey-600"
				size="text-xs"
				className="tracking-[0.5px]"
			>
				{label}
			</Text>
			<div
				className={cn(
					styles,
					'text-sm',
					'tracking-[0.5px]',
					'px-1.5',
					'rounded-xl',
					selectedStyles,
				)}
			>
				{selected && (
					<svg
						width="8"
						height="8"
						viewBox="0 0 8 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="4" cy="4" r="4" fill="#FCFCFC" />
					</svg>
				)}
				{amount}
			</div>
		</div>
	)
}

export default CounterBadge
