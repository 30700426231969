import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPulse = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 20" {...props}>
		<path
			fill="currentColor"
			d="m23.138 11.837-5.463-7.804a4.765 4.765 0 0 0-6.638-1.17 4.75 4.75 0 0 0-1.729 2.241C9.188 2.634 7.167.667 4.667.667A4.664 4.664 0 0 0 0 5.333v9.334a4.664 4.664 0 0 0 4.667 4.666 4.664 4.664 0 0 0 4.666-4.666V8.453c.138.358.304.712.534 1.042l5.466 7.804a4.75 4.75 0 0 0 3.909 2.033 4.763 4.763 0 0 0 3.896-7.496M6.666 10h-4V5.333a2 2 0 0 1 4 0zm8.116 1.87L12.05 7.968a2.07 2.07 0 0 1-.346-1.567c.096-.55.404-1.033.863-1.354a2.093 2.093 0 0 1 2.92.517l2.734 3.903z"
		/>
	</svg>
)
export default SvgPulse
