import { Text } from '@/components/sharedComponents/Text'
import {
	RadioGroupItemProps,
	Item,
	Indicator,
	Root,
} from '@radix-ui/react-radio-group'
import { forwardRef } from 'react'
import { cn } from '@/lib/utils'

/**
 * El valor value se usará como id con el prefio 'id-', si considera esto un problema, por favor añadí un id específico.
 * El id es necesario para que el label esté matcheando con el button, para que al clickear el texto funcione el seleccionar.
 */
export const RadioItem = forwardRef<
	HTMLButtonElement,
	RadioGroupItemProps & {
		itemClassName?: string
		indicatorClassName?: string
		id: string
	}
>(
	(
		{
			children,
			className,
			itemClassName,
			indicatorClassName,
			value,
			id,
			...props
		},
		ref,
	) => {
		return (
			<div
				aria-disabled={props.disabled}
				className={cn(
					props.disabled ? 'opacity-45' : undefined,
					'w-full h-12 bg-grey-6 border border-solid border-grey-5 rounded flex items-center cursor-pointer justify-between space-x-4 pr-2',
					className,
				)}
			>
				<label
					className="w-full h-full cursor-pointer  pl-4 flex content-center items-center justify-items-center"
					htmlFor={`${id}-button`}
					aria-labelledby={`${id}-text`}
				>
					<Text
						tag="span"
						color="text-grey-800"
						weight="font-regular"
						className="mr-2"
						id={`${id}-text`}
					>
						{children}
					</Text>
				</label>
				<Item
					ref={ref}
					className={cn(
						' group/item bg-white size-6 rounded-full outline-none cursor-pointer border-grey-600 border-2 border-solid shrink-0 flex justify-center content-center items-center justify-items-center aria-checked:border-primary-700',
						itemClassName,
					)}
					id={`${id}-button`}
					value={value}
					{...props}
				>
					<Indicator
						className={cn(
							'flex items-center align-middle  content-center justify-center width-full relative after:block after:w-[10px] after:h-[10px] after:rounded-xl after:bg-primary-700 after:content[""]  size-6 cursor-pointer flex-shrink-0 rounded-[100%]',
							indicatorClassName,
						)}
					/>
				</Item>
			</div>
		)
	},
)

RadioItem.displayName = 'radioItem'

export { Root as RadioRoot }
