import { format } from 'date-fns'
import { Icon } from '@/client'
import { cn } from '@/lib/utils'

function StatusChatIcon({ status }: { status: IChatMessage['status'] }) {
	if (status === 'isLoading') {
		return <Icon name="schedule" size="size-4" />
	}

	if (status === 'isOnServer') {
		return <Icon name="done" size="size-4" />
	}

	if (status === 'wasSended') {
		return <Icon name="doneAll" size="size-4" />
	}

	if (status === 'wasReaded') {
		return <Icon name="doneAll" size="size-4" color="text-success" />
	}

	if (status === 'isError') {
		return <Icon name="errorOutline" color="text-error-100" size="size-4" />
	}
}

interface IChatMessageSelf {
	status: 'isLoading' | 'isOnServer' | 'wasReaded' | 'isError' | 'wasSended'
	sendTime: Date
	messageOrigin: 'self'
	children: string | JSX.Element
}

interface IChatMessageOther {
	status: 'wasSended'
	sendTime: Date
	messageOrigin: 'other'
	children: string | JSX.Element
}

export type IChatMessage = IChatMessageSelf | IChatMessageOther


export function ChatMessage({
	status,
	sendTime,
	messageOrigin,
	children,
}: IChatMessage) {
	return (
		<li
			data-origin={messageOrigin}
			className={cn(
				'flex flex-col rounded p-2.5 max-w-[80%]',
				messageOrigin === 'self'
					? "rounded-tr-none self-end after:w-[10px] after:absolute after:h-[4px] after:left-[100%] after:block after:border-t-[8px]  after:border-l-transparent after:border-r-[12px] after:border-r-transparent after:top-[0%] after:content-[''] relative"
					: "rounded-tl-none self-start after:content-[''] after:w-[10px] after:absolute after:h-[4px] after:right-[100%] after:block after:border-t-[8px] after:border-r-transparent after:border-l-[12px] after:border-l-transparent after:top-[0%] relative",
			)}
		>
			<p> {children} </p>
			<span className="flex items-center self-end ml-6 text-xs mt-1">
				<time
					className="mr-1"
					dateTime={format(sendTime, "yyyy-MM-dd'T'HH:mm:ss")}
				>
					{format(sendTime, 'hh:mm a')}
				</time>
				{messageOrigin === 'self' && <StatusChatIcon status={status} />}
			</span>
		</li>
	)
}
