import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHomeHealth = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_3654_40090"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_3654_40090)">
			<path
				d="M10.75 16.75H13.25V14.25H15.75V11.75H13.25V9.25002H10.75V11.75H8.25V14.25H10.75V16.75ZM4.5 20.5V9.25002L12 3.61542L19.5 9.25002V20.5H4.5ZM5.99997 19H18V9.99999L12 5.49999L5.99997 9.99999V19Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgHomeHealth
