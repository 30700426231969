import { ReactNode, createElement } from 'react'
import { IText,  } from './IText'
import { cn } from '@/lib/utils'

type CustomTagProps<T extends keyof JSX.IntrinsicElements> = {
	as: T
	children: ReactNode
	props: Omit<IText, 'children' | 'tag'>
}

const CustomTag = <T extends keyof JSX.IntrinsicElements>({
	as,
	children,
	props,
}: CustomTagProps<T>) => {
	return createElement(as, { ...props }, children)
}

const Text = ({
	children,
	tag='div',
	className,
	id,
	'aria-hidden' : ariaHidden,
	weight = 'font-regular',
	size = 'inherit',
	color = 'inherit',
	'data-testid' : dataTestId = `text-${tag}-data-id`,
	...props
}: IText) => {

	
	const tailwindStyles = cn(`${color}`, `${weight}`, size, className)

	return (
		<CustomTag
			as={tag}
			props={{
				weight,
				size,
				color,
				id,
				'aria-hidden': ariaHidden,
				'data-testid': dataTestId,
				className: tailwindStyles,
				...props
			}}
		>
			{children}
		</CustomTag>
	)
}

export default Text
