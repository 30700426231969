import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAmbulance = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 16 16" {...props}>
		<path
			fill="currentColor"
			d="M23.4 15.6h-.6v-4.054a1.8 1.8 0 0 0-.529-1.271l-3.746-3.746a1.8 1.8 0 0 0-1.271-.53H15.6V4.2a1.8 1.8 0 0 0-1.8-1.8h-12A1.8 1.8 0 0 0 0 4.2v12A1.8 1.8 0 0 0 1.8 18h.6a3.6 3.6 0 0 0 7.2 0h4.8a3.6 3.6 0 0 0 7.2 0h1.8c.33 0 .6-.27.6-.6v-1.2c0-.33-.27-.6-.6-.6M6 19.8a1.8 1.8 0 1 1 0-3.601 1.8 1.8 0 0 1 0 3.6m5.4-9.3a.3.3 0 0 1-.3.3H9v2.1a.3.3 0 0 1-.3.3H6.9a.3.3 0 0 1-.3-.3v-2.1H4.5a.3.3 0 0 1-.3-.3V8.7a.3.3 0 0 1 .3-.3h2.1V6.3a.3.3 0 0 1 .3-.3h1.8a.3.3 0 0 1 .3.3v2.1h2.1a.3.3 0 0 1 .3.3zm6.6 9.3a1.8 1.8 0 1 1 0-3.601 1.8 1.8 0 0 1 0 3.6m3-7.8h-5.4V7.8h1.654L21 11.546z"
		/>
	</svg>
)
export default SvgAmbulance
