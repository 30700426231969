import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrendingUp = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_6505_79239"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_6505_79239)">
			<path
				d="M3.55375 17.548L2.5 16.4943L9.352 9.59225L13.352 13.5923L18.9655 8.048H16V6.548H21.5V12.048H20V9.10201L13.352 15.75L9.352 11.75L3.55375 17.548Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgTrendingUp
