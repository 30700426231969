import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSwapVert = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_6258_76799"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_6258_76799)">
			<path
				d="M8.404 12.6538V5.373L5.56925 8.20775L4.5 7.15375L9.15375 2.5L13.8077 7.15375L12.7385 8.20775L9.90375 5.373V12.6538H8.404ZM14.8365 21.5L10.1828 16.8462L11.252 15.7923L14.0865 18.627V11.3463H15.5865V18.627L18.4212 15.7923L19.4902 16.8462L14.8365 21.5Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgSwapVert
