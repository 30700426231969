import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTurbo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={16}
		height={16}
		fill="none"
		{...props}
	>
		<rect width={16} height={16} fill="#00B186" rx={8} />
		<path fill="#FCFCFC" d="M7 9v5l4-7H9V2L5 9z" />
	</svg>
)
export default SvgTurbo
