import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUserInjured = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M14.502.562A5.95 5.95 0 0 0 12 0a5.995 5.995 0 0 0-5.555 3.75h3.806zm3.053 3.188a6 6 0 0 0-1.71-2.321L12.749 3.75zM12 12a6 6 0 0 0 6-6c0-.257-.044-.502-.076-.75H6.075C6.045 5.498 6 5.743 6 6a6 6 0 0 0 6 6m-6.75 2.048V24h6.012L6.647 13.616c-.48.089-.95.234-1.397.432M1.5 21.75A2.25 2.25 0 0 0 3.75 24v-8.989C2.385 16.167 1.5 17.871 1.5 19.8zm12-2.25h-2.596l2 4.5h.596c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25m2.7-6h-.783a8.2 8.2 0 0 1-3.417.75 8.2 8.2 0 0 1-3.417-.75h-.345l2 4.5H13.5a3.754 3.754 0 0 1 3.75 3.75c0 .848-.293 1.621-.77 2.25h3.77a2.25 2.25 0 0 0 2.25-2.25V19.8a6.3 6.3 0 0 0-6.3-6.3"
		/>
	</svg>
)
export default SvgUserInjured
