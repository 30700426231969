import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlusCircle = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#clip0_9338_25890)">
			<path
				d="M10.0007 1.66536C5.39828 1.66536 1.66732 5.39632 1.66732 9.9987C1.66732 14.6011 5.39828 18.332 10.0007 18.332C14.603 18.332 18.334 14.6011 18.334 9.9987C18.334 5.39632 14.603 1.66536 10.0007 1.66536Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M13.334 10H6.66732"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M10 13.332V6.66536"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
		</g>
		<defs>
			<clipPath id="clip0_9338_25890">
				<rect
					width="20"
					height="20"
					fill="white"
					transform="matrix(-1 0 0 -1 20 20)"
				/>
			</clipPath>
		</defs>
	</svg>
)
export default SvgPlusCircle
