import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSortByAlpha = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_6258_76854"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_6258_76854)">
			<path
				d="M2.30762 16.7885L6.03837 7.21149H7.63062L11.3421 16.7885H9.75362L8.88462 14.4077H4.76512L3.88837 16.7885H2.30762ZM5.23062 13.1115H8.36912L6.88062 8.93848H6.74987L5.23062 13.1115ZM13.9114 16.7885V15.3692L19.0384 8.56924H14.1114V7.21149H20.7384V8.63073L15.6421 15.4307H20.7884V16.7885H13.9114ZM9.23062 4.90374L11.9999 2.13474L14.7691 4.90374H9.23062ZM11.9999 21.8652L9.23062 19.0962H14.7691L11.9999 21.8652Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgSortByAlpha
