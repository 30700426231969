import * as React from 'react'
import type { SVGProps } from 'react'
const SvgImage = (props: SVGProps<SVGSVGElement>) => (
	<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_2290_45873"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_2290_45873)">
			<path
				d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30773C3.5 4.8026 3.675 4.37503 4.025 4.02503C4.375 3.67503 4.80257 3.50003 5.3077 3.50003H18.6923C19.1974 3.50003 19.625 3.67503 19.975 4.02503C20.325 4.37503 20.5 4.8026 20.5 5.30773V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM6.75003 16.75H17.3269L14.0384 12.3654L11.2308 16.0192L9.23075 13.4616L6.75003 16.75ZM8.49998 9.74998C8.84613 9.74998 9.14099 9.62819 9.38458 9.38461C9.62816 9.14102 9.74995 8.84616 9.74995 8.50001C9.74995 8.15386 9.62816 7.85899 9.38458 7.61541C9.14099 7.37182 8.84613 7.25003 8.49998 7.25003C8.15383 7.25003 7.85896 7.37182 7.61538 7.61541C7.37179 7.85899 7.25 8.15386 7.25 8.50001C7.25 8.84616 7.37179 9.14102 7.61538 9.38461C7.85896 9.62819 8.15383 9.74998 8.49998 9.74998Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgImage
