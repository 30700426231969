import * as React from 'react'
import type { SVGProps } from 'react'
const SvgVitalSigns = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_5881_39990"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" />
		</mask>
		<g mask="url(#mask0_5881_39990)">
			<path
				d="M9 20C8.68333 20 8.4 19.9083 8.15 19.725C7.9 19.5417 7.71667 19.3083 7.6 19.025L5.3 13H1V11H6.7L9 17.1L13.6 4.975C13.7167 4.69167 13.9 4.45833 14.15 4.275C14.4 4.09167 14.6833 4 15 4C15.3167 4 15.6 4.09167 15.85 4.275C16.1 4.45833 16.2833 4.69167 16.4 4.975L18.7 11H23V13H17.3L15 6.9L10.4 19.025C10.2833 19.3083 10.1 19.5417 9.85 19.725C9.6 19.9083 9.31667 20 9 20Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgVitalSigns
