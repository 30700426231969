import { IText, Text } from "@/components/sharedComponents/Text";
import { cn } from "@/lib/utils";


type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export default function Badge( {children,tag = 'span', className, status, ...props} : Optional<IText, 'tag'>&{status : 'error' | 'warning' | 'success'}){

    const statusColor : Record<typeof status,`bg-${string}`> = {
        error: 'bg-[#E71D1D]',
        success: 'bg-[#00B186]',
        warning: 'bg-[#FFB700]'
    }

    return <Text className={cn('px-2 py.0.5 text-white rounded-xl',statusColor[status],className)} tag={tag} {...props}>
        {children}
    </Text>
}