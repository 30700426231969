import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M5.34613 19.5V10.3981L2.40188 12.625L1.5 11.4289L11.9999 3.50003L16.173 6.63658V4.50003H18.6153V8.49426L22.5095 11.4289L21.6076 12.625L18.6634 10.3981V19.5H13.1154V13.8846H10.8846V19.5H5.34613ZM6.8461 18H9.3846V12.3847H14.6153V18H17.1634V9.26731L11.9999 5.39038L6.8461 9.26731V18ZM10.0961 10.0154H13.9038C13.9038 9.51411 13.7134 9.10226 13.3326 8.77983C12.9519 8.45738 12.5076 8.29616 11.9999 8.29616C11.4923 8.29616 11.048 8.45709 10.6673 8.77896C10.2865 9.10082 10.0961 9.51296 10.0961 10.0154Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgHouse
