import * as React from 'react'
import type { SVGProps } from 'react'
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path d="M11.9995 10.4538L7.39953 15.0538L6.3457 14L11.9995 8.34616L17.6534 14L16.5995 15.0538L11.9995 10.4538Z" 
			fill="currentColor"
		/>
	</svg>
)
export default SvgArrowUp
