import { Dispatch, RefObject, SetStateAction, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOnClickOutsideClose(ref : RefObject<HTMLDivElement>, setOpen: Dispatch<SetStateAction<boolean>>
) {
  useEffect(() => {
    
    /**
     * Alert if clicked on outside of element
     * any temporal porque tiparlo es difícil
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event : any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

