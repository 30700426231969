import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#clip0_1811_12471)">
			<path
				d="M18 9L12 15L6 9"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1811_12471">
				<rect width="24" height="24" fill="transparent" />
			</clipPath>
		</defs>
	</svg>
)
export default SvgChevronDown
