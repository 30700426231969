import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNote = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M19 5V14H14V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H15L21 15V5C21 3.9 20.1 3 19 3Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgNote
