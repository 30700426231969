import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMedicalInfo = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_2464_22410"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_2464_22410)">
			<path
				d="M7 18H9V16H11V14H9V12H7V14H5V16H7V18ZM13 14.5H19V13H13V14.5ZM13 17.5H17V16H13V17.5ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V9C2 8.45 2.19583 7.97917 2.5875 7.5875C2.97917 7.19583 3.45 7 4 7H9V4C9 3.45 9.19583 2.97917 9.5875 2.5875C9.97917 2.19583 10.45 2 11 2H13C13.55 2 14.0208 2.19583 14.4125 2.5875C14.8042 2.97917 15 3.45 15 4V7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V20C22 20.55 21.8042 21.0208 21.4125 21.4125C21.0208 21.8042 20.55 22 20 22H4ZM4 20H20V9H15C15 9.55 14.8042 10.0208 14.4125 10.4125C14.0208 10.8042 13.55 11 13 11H11C10.45 11 9.97917 10.8042 9.5875 10.4125C9.19583 10.0208 9 9.55 9 9H4V20ZM11 9H13V4H11V9Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgMedicalInfo
