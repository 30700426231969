'use client'
/* eslint-disable react/no-unknown-property */

import * as React from 'react'
import { Dialog, DialogContent, type DialogProps } from '@radix-ui/react-dialog'
import { Command as CommandPrimitive } from 'cmdk'

import { cn } from '@/lib/utils'
import { Icon, IconsNames } from '../Icon'

const Command = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
	<CommandPrimitive
		ref={ref}
		className={cn(
			'flex w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground',
			className,
		)}
		{...props}
	/>
))
Command.displayName = CommandPrimitive.displayName

interface CommandDialogProps extends DialogProps {}

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
	return (
		<Dialog {...props}>
			<DialogContent className="overflow-hidden p-0">
				<Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
					{children}
				</Command>
			</DialogContent>
		</Dialog>
	)
}

const CommandInput = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive.Input>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
		setValue: React.Dispatch<React.SetStateAction<string | null>>
		label?: string
	}
>(({ className, label, ...props }, ref) => (
	<div
		className="flex items-center border-b px-3 bg-white border-grey-3 focus-within:border-primary border border-solid focus-within:shadow-primary rounded-lg focus-within:shadow-input relative w-full mt-1"
		cmdk-input-wrapper=""
	>
		<Icon className="mr-2 h-4 w-4 text-grey-3 shrink-0" name="search" />
		{label && (
			<label
				htmlFor={props.id}
				className={` 
			shrink-0	
			text-grey-600 
			-top-3 
			left-8 
			flex  rounded-md 
			group-focus-within:absolute group-focus-within:bg-white group-focus-within:p-1 group-focus-within:text-xxs group-focus-within:text-grey-900 
			${props.value ? 'bg-white p-1 text-xxs text-grey-900 absolute' : ''}`}
			>
				{label}
			</label>
		)}
		<CommandPrimitive.Input
			ref={ref}
			className={cn(
				'flex h-10 w-full rounded-md bg-white py-3 text-sm outline-none placeholder:text-white group-focus-within:placeholder:text-grey-600 ',
				className,
			)}
			{...props}
		/>
		{!!props.value === true && (
			<Icon
				name="cancelOutline"
				onClick={() => {
					props?.setValue('')
				}}
			/>
		)}
		<Icon className="group-focus-within:rotate-180" name="chevronDown" />
	</div>
))

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
	<CommandPrimitive.List
		ref={ref}
		className={cn(
			'max-h-[200px] overflow-scroll bg-white',
			'focus-within:border-primary focus-within:border focus-within:border-solid focus-within:shadow-input focus-within:shadow-primary focus-within:rounded-lg focus-within:mt-2 focus-within:px-4',
			'border-primary border border-solid shadow-primary rounded-lg mt-2 px-4 shadow-input ',
			className,
		)}
		style={
			/*  esto es por el siguiente bug de tailwind:  https://github.com/tailwindlabs/tailwindcss/issues/2740*/
			{
				padding: '0 24px',
				marginTop: '8px',
				overflow: 'scroll'
			}
		}
		{...props}
	/>
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive.Empty>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
	<CommandPrimitive.Empty
		ref={ref}
		className="py-6 text-center text-sm"
		{...props}
	/>
))

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive.Group>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
	<CommandPrimitive.Group
		ref={ref}
		className={cn(
			'overflow-hidden text-grey-1 [&_[cmdk-group-heading]]:py-4 [&_[cmdk-group-heading]]:text-base [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-grey-1 flex flex-col w-full',
			className,
		)}
		{...props}
	/>
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandSeparator = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive.Separator>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
	<CommandPrimitive.Separator
		ref={ref}
		className={cn('-mx-1 h-px bg-border', className)}
		{...props}
	/>
))
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = React.forwardRef<
	React.ElementRef<typeof CommandPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
		name?: IconsNames
		onClick?: React.MouseEventHandler<HTMLButtonElement>
	}
>(({ className, name, onClick, ...props }, ref) => (
	<CommandPrimitive.Item
		ref={ref}
		className={cn(
			'relative bg-white flex text-grey-1 outline-none w-full hover:bg-grey-6 rounded-xl px-2',
			className,
		)}
		asChild
		{...props}
	>
		<button type='button' className="flex py-2" onClick={onClick}>
			{name && (
				<Icon
					name={name}
					size="size-6"
					color="text-grey-600"
					className="mr-4"
					stroke="stroke-[0.3]"
				/>
			)}
			{props?.children}
		</button>
	</CommandPrimitive.Item>
))

CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandShortcut = ({
	className,
	...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
	return (
		<span
			className={cn(
				'ml-auto text-xs tracking-widest text-muted-foreground',
				className,
			)}
			{...props}
		/>
	)
}
CommandShortcut.displayName = 'CommandShortcut'

export {
	Command,
	CommandDialog,
	CommandInput,
	CommandList,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandShortcut,
	CommandSeparator,
}
