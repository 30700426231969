import * as React from 'react'
import type { SVGProps } from 'react'
const SvgJpg = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path fill="#fff" d="M0 0h24v24H0z" />
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.5 2.5a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-17 6.44h-3.4a2 2 0 0 1-2-2V2.5z"
			clipRule="evenodd"
		/>
		<path fill="currentColor" d="M15 7.975h4.5L15 2.5z" />
		<path
			fill="#FCFCFC"
			d="M15.053 15.123q-.643 0-1.091-.26a1.7 1.7 0 0 1-.675-.73q-.232-.47-.232-1.102 0-.65.237-1.125.243-.475.697-.736.454-.26 1.09-.26.401 0 .757.121.36.122.588.325l-.27.701a1.6 1.6 0 0 0-.507-.278 1.8 1.8 0 0 0-.562-.087q-.578 0-.87.348-.285.342-.285.99 0 .656.291.992.297.336.875.336.308 0 .648-.104v-.8h-.82v-.65h1.511v2.006a2.5 2.5 0 0 1-.648.232q-.368.081-.734.081M9.447 15.06v-4.087h1.74q.636 0 .982.348.345.342.345.956t-.345.963q-.345.348-.983.347h-.902v1.473zm.837-2.168h.762q.664 0 .664-.615 0-.602-.664-.602h-.762zM6.905 15.118l-.06-.73.417-.024q.432-.03.432-.487v-2.904h.837v2.893q0 1.147-1.135 1.229z"
		/>
	</svg>
)
export default SvgJpg
