import { cn } from '@/lib/utils'
import React, { useState, useRef, forwardRef } from 'react'

export interface Time {
	hours: string | null
	minutes: string | null
}

export interface IInputTime {
	defaultValue?: Time
	className?: string
	onChange?: (time: Time) => void
	value?: Time
	label?: string
}

/**
 * Componente TimePicker
 * Permite la entrada de horas y minutos con validación, manejo de foco automático y restricción de caracteres.
 */
const InputTime = forwardRef<HTMLInputElement, IInputTime>(
	({ defaultValue, className, onChange, value: forcedValue, label }, ref) => {
		const [time, setTime] = useState<Time>(forcedValue ?? defaultValue ?? { hours: null, minutes: null })
		const hoursRef = useRef<HTMLInputElement>(null)
		const minutesRef = useRef<HTMLInputElement>(null)
		const isEmpty = !!time.hours === false && !!time.minutes === false

		/**
		 * Maneja los cambios en los inputs de horas y minutos.
		 * @param e - El evento de cambio del input.
		 */
		const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value: inputValue } = e.target

			const thereIsAForcedValue =
				(name === 'hours' || name === 'minutes') &&
				forcedValue &&
				forcedValue[name]

			const value = thereIsAForcedValue
				? forcedValue[name] ?? inputValue
				: inputValue

			if (name === 'hours') {
				// Valida que el valor de horas esté entre 0 y 23
				if (
					value === '' ||
					(Number(value) >= 0 && Number(value) <= 23)
				) {
					setTime({ ...time, [name]: value })
					if (onChange) {
						onChange({ ...time, [name]: value })
					}
					// Mueve el foco al campo de minutos si se ingresaron 2 caracteres
					if (value.length === 2) {
						minutesRef.current?.focus()
					}
				}
			}

			if (name === 'minutes') {
				// Valida que el valor de minutos esté entre 0 y 59
				if (
					value === '' ||
					(Number(value) >= 0 && Number(value) <= 59)
				) {
					setTime({ ...time, [name]: value })
					if (onChange) {
						onChange({ ...time, [name]: value })
					}
					// Mueve el foco al campo de horas si el campo de minutos está vacío
					if (value === '') {
						hoursRef.current?.focus()
					}
				}
			}
		}

		/**
		 * Maneja los eventos de teclas para mover el foco entre inputs.
		 * @param e - El evento de teclado.
		 * @param field - El campo actual ('hours' o 'minutes').
		 */
		const handleKeyDown = (
			e: React.KeyboardEvent<HTMLInputElement>,
			field: 'hours' | 'minutes',
		) => {
			if (field === 'hours' && e.key === 'ArrowRight') {
				// Mueve el foco al campo de minutos si se presiona 'ArrowRight'
				minutesRef.current?.focus()
			}
			if (field === 'minutes' && e.key === 'ArrowLeft') {
				// Mueve el foco al campo de horas si se presiona 'ArrowLeft'
				hoursRef.current?.focus()
			}

			// Evita que se ingresen caracteres no numéricos, pero permite Tab y Shift+Tab
			if (
				!/[0-9]/.test(e.key) &&
				e.key !== 'Backspace' &&
				e.key !== 'ArrowLeft' &&
				e.key !== 'ArrowRight' &&
				e.key !== 'Tab'
			) {
				e.preventDefault()
			}
		}

	return (
		<div
			ref={ref}
			className={cn(
				' group w-full flex items-center p-2 rounded bg-white border border-solid border-grey-5 focus-within:outline focus-within:outline-grey-3 focus-within:outline-2 relative shadow-input',
				className,
			)}
		>
				{label &&
          <label
            className={cn(
              'text-grey-3 absolute z-[0] ',
              'flex justify-between w-full pl-2 pr-5',
              isEmpty
                ? 'group-focus:sr-only group-focus-within:sr-only group-focus:visible:sr-only'
                : 'sr-only',
            )}
          >
					<span>{label}</span>
					<span aria-label="El formato es HH:MM">
						{' '}
						HH:MM
					</span>
				</label>
					}
				<input
					ref={hoursRef}
					inputMode="numeric"
					type="number"
					name="hours"
					value={time.hours ?? undefined}
					onChange={handleTimeChange}
					onKeyDown={e => handleKeyDown(e, 'hours')}
					placeholder="HH"
					min="0"
					max="23"
					className={cn(
						'mx-0 group-focus-within:w-7 text-center border-none rounded focus:outline-none group-focus-within:placeholder-grey-3 z-10 placeholder-transparent focus:placeholder-grey-3 bg-transparent w-full',
						isEmpty ? 'w-full' : 'w-7',
					)}
					style={{
						// Oculta las flechas en navegadores WebKit
						appearance: 'none',
						// Oculta las flechas en Firefox
						MozAppearance: 'textfield',
					}}
					defaultValue={
						defaultValue?.hours ? defaultValue?.hours : undefined
					}
				/>
				<span
					className={cn(
						'group-focus-within:text-grey-1 text-transparent ',
						isEmpty ? '' : 'text-grey-1',
					)}
				>
					:
				</span>
				<input
					ref={minutesRef}
					type="number"
					inputMode="numeric"
					name="minutes"
					value={time.minutes ?? undefined}
					onChange={handleTimeChange}
					onKeyDown={e => handleKeyDown(e, 'minutes')}
					placeholder="MM"
					min="0"
					max="59"
					className={cn(
						'mx-0 group-focus-within:w-7 group-focus-within:placeholder-grey-3 text-center border-none rounded focus:outline-none z-10 placeholder-transparent focus:placeholder-grey-3 bg-transparent w-0',
						isEmpty ? '' : 'w-7',
					)}
					style={{
						// Oculta las flechas en navegadores WebKit
						appearance: 'none',
						// Oculta las flechas en Firefox
						MozAppearance: 'textfield',
					}}
					defaultValue={
						defaultValue?.minutes
							? defaultValue?.minutes
							: undefined
					}
				/>
			</div>
		)
	},
)

InputTime.displayName = 'InputTime'

export default InputTime