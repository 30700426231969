import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCastScreen = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
		<path
			fill="currentColor"
			fillRule="nonzero"
			d="M23.75 5A2.25 2.25 0 0 1 26 7.25v13.5A2.25 2.25 0 0 1 23.75 23H4.25A2.25 2.25 0 0 1 2 20.75V7.25A2.25 2.25 0 0 1 4.25 5zm0 1.5H4.25a.75.75 0 0 0-.75.75v13.5c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75V7.25a.75.75 0 0 0-.75-.75M13.998 8.62a.75.75 0 0 1 .53.22l3.255 3.254a.75.75 0 0 1-1.061 1.06l-1.974-1.973v7.446a.75.75 0 1 1-1.5 0v-7.444l-1.97 1.972a.75.75 0 1 1-1.061-1.06l3.25-3.255a.75.75 0 0 1 .53-.22"
		/>
	</svg>
)
export default SvgCastScreen
