import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M21.394 10.064 4.894.309C3.554-.483 1.5.286 1.5 2.245V21.75c0 1.758 1.908 2.817 3.394 1.936l16.5-9.75c1.472-.867 1.476-3.005 0-3.872"
		/>
	</svg>
)
export default SvgPlay
