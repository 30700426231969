import * as React from 'react'
import type { SVGProps } from 'react'
const SvgDone = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_6908_91016"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_6908_91016)">
			<path
				d="M9.55032 17.6538L4.21582 12.3193L5.28482 11.25L9.55032 15.5155L18.7158 6.35001L19.7848 7.41926L9.55032 17.6538Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgDone
