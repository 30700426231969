import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCallEnd = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_5419_54538"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_5419_54538)">
			<path
				d="M11.9998 8C13.9665 8 15.904 8.39583 17.8123 9.1875C19.7206 9.97917 21.4165 11.1667 22.8998 12.75C23.0998 12.95 23.1998 13.1833 23.1998 13.45C23.1998 13.7167 23.0998 13.95 22.8998 14.15L20.5998 16.4C20.4165 16.5833 20.204 16.6833 19.9623 16.7C19.7206 16.7167 19.4998 16.65 19.2998 16.5L16.3998 14.3C16.2665 14.2 16.1665 14.0833 16.0998 13.95C16.0331 13.8167 15.9998 13.6667 15.9998 13.5V10.65C15.3665 10.45 14.7165 10.2917 14.0498 10.175C13.3831 10.0583 12.6998 10 11.9998 10C11.2998 10 10.6165 10.0583 9.94981 10.175C9.28314 10.2917 8.63314 10.45 7.9998 10.65V13.5C7.9998 13.6667 7.96647 13.8167 7.8998 13.95C7.83314 14.0833 7.73314 14.2 7.5998 14.3L4.6998 16.5C4.4998 16.65 4.27897 16.7167 4.0373 16.7C3.79564 16.6833 3.58314 16.5833 3.3998 16.4L1.0998 14.15C0.899805 13.95 0.799805 13.7167 0.799805 13.45C0.799805 13.1833 0.899805 12.95 1.0998 12.75C2.56647 11.1667 4.25814 9.97917 6.1748 9.1875C8.09147 8.39583 10.0331 8 11.9998 8Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgCallEnd
