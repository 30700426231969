import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBrainCircuit = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M11.9998 5C12.001 4.60003 11.9222 4.20386 11.768 3.8348C11.6139 3.46573 11.3874 3.13122 11.1021 2.85094C10.8168 2.57066 10.4783 2.35027 10.1065 2.20273C9.73472 2.05519 9.33721 1.98347 8.93733 1.99181C8.53744 2.00014 8.14326 2.08836 7.77797 2.25126C7.41268 2.41417 7.08365 2.64847 6.81023 2.9404C6.53682 3.23233 6.32454 3.57598 6.18588 3.95115C6.04722 4.32632 5.98499 4.72542 6.00283 5.125C5.41503 5.27613 4.86933 5.55905 4.40706 5.95231C3.94479 6.34557 3.57807 6.83887 3.33467 7.39485C3.09128 7.95082 2.97759 8.55489 3.00222 9.16131C3.02685 9.76773 3.18915 10.3606 3.47683 10.895C2.97101 11.3059 2.57326 11.8342 2.31817 12.4339C2.06308 13.0336 1.95839 13.6866 2.01319 14.336C2.068 14.9854 2.28065 15.6115 2.63264 16.16C2.98462 16.7085 3.46529 17.1627 4.03283 17.483C3.96275 18.0252 4.00457 18.5761 4.15572 19.1015C4.30686 19.627 4.56413 20.1158 4.91161 20.538C5.2591 20.9601 5.68944 21.3065 6.17605 21.5558C6.66266 21.805 7.19521 21.9519 7.74081 21.9873C8.28641 22.0227 8.83347 21.9459 9.34822 21.7616C9.86296 21.5773 10.3345 21.2894 10.7336 20.9158C11.1327 20.5421 11.451 20.0906 11.6688 19.5891C11.8866 19.0876 11.9992 18.5467 11.9998 18V5Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 13C9.83956 12.7047 10.5727 12.167 11.1067 11.455C11.6407 10.743 11.9515 9.88867 12 9"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.00293 5.125C6.0227 5.60873 6.15926 6.0805 6.40093 6.5"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.47656 10.896C3.6595 10.747 3.85527 10.6145 4.06156 10.5"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.0002 18C5.31103 18.0003 4.63347 17.8226 4.0332 17.484"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 13H16"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 18H18C18.5304 18 19.0391 18.2107 19.4142 18.5858C19.7893 18.9609 20 19.4696 20 20V21"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 8H20"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 8V5C16 4.46957 16.2107 3.96086 16.5858 3.58579C16.9609 3.21071 17.4696 3 18 3"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16 13.5C16.2761 13.5 16.5 13.2761 16.5 13C16.5 12.7239 16.2761 12.5 16 12.5C15.7239 12.5 15.5 12.7239 15.5 13C15.5 13.2761 15.7239 13.5 16 13.5Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18 3.5C18.2761 3.5 18.5 3.27614 18.5 3C18.5 2.72386 18.2761 2.5 18 2.5C17.7239 2.5 17.5 2.72386 17.5 3C17.5 3.27614 17.7239 3.5 18 3.5Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20 21.5C20.2761 21.5 20.5 21.2761 20.5 21C20.5 20.7239 20.2761 20.5 20 20.5C19.7239 20.5 19.5 20.7239 19.5 21C19.5 21.2761 19.7239 21.5 20 21.5Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20 8.5C20.2761 8.5 20.5 8.27614 20.5 8C20.5 7.72386 20.2761 7.5 20 7.5C19.7239 7.5 19.5 7.72386 19.5 8C19.5 8.27614 19.7239 8.5 20 8.5Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
export default SvgBrainCircuit
