import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTrendingDown = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_6505_79240"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_6505_79240)">
			<path
				d="M16 17.548V16.048H18.9655L13.352 10.5038L9.352 14.5038L2.5 7.60201L3.55375 6.548L9.352 12.3463L13.352 8.34625L20 14.9943V12.048H21.5V17.548H16Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgTrendingDown
