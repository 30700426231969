import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHospital = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 18 18" {...props}>
		<g clipPath="url(#clip0_2762_48027)">
			<path
				d="M9 4.5V7.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M10.5 10.5H7.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M10.5 13.5H7.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M10.5 6H7.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M13.5 9H15C15.3978 9 15.7794 9.15804 16.0607 9.43934C16.342 9.72064 16.5 10.1022 16.5 10.5V15C16.5 15.3978 16.342 15.7794 16.0607 16.0607C15.7794 16.342 15.3978 16.5 15 16.5H3C2.60218 16.5 2.22064 16.342 1.93934 16.0607C1.65804 15.7794 1.5 15.3978 1.5 15V8.25C1.5 7.85218 1.65804 7.47064 1.93934 7.18934C2.22064 6.90804 2.60218 6.75 3 6.75H4.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="transparent"
			/>
			<path
				d="M13.5 16.5V3C13.5 2.60218 13.342 2.22064 13.0607 1.93934C12.7794 1.65804 12.3978 1.5 12 1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V16.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				fill="transparent"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2762_48027">
				<rect width="18" height="18" fill="transparent" />
			</clipPath>
		</defs>
	</svg>
)
export default SvgHospital
