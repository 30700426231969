import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFileMedical = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 22 24" {...props}>
		<path
			fill="currentColor"
			d="M14 6.375V0H4.625C4.002 0 3.5.502 3.5 1.125V12H.875a.376.376 0 0 0-.375.375v.75c0 .206.169.375.375.375H7.48c.14 0 .272.08.337.206l.933 1.866 2.662-5.33a.374.374 0 0 1 .67 0L13.71 13.5H17c.413 0 .75.338.75.75s-.337.75-.75.75h-4.214l-1.036-2.072-2.662 5.33a.374.374 0 0 1-.67 0L6.785 15H3.5v7.875c0 .623.502 1.125 1.125 1.125h15.75c.623 0 1.125-.502 1.125-1.125V7.5h-6.375A1.13 1.13 0 0 1 14 6.375m7.172-1.453L16.582.328A1.12 1.12 0 0 0 15.787 0H15.5v6h6v-.286c0-.295-.117-.581-.328-.792"
		/>
	</svg>
)
export default SvgFileMedical
