import * as React from 'react'
import type { SVGProps } from 'react'
const SvgPrescription = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M16.95 20.65L18.1 19.5L16.5 17.9L15.35 19.05C15.1167 19.2833 15 19.55 15 19.85C15 20.15 15.1167 20.4167 15.35 20.65C15.5833 20.8833 15.85 21 16.15 21C16.45 21 16.7167 20.8833 16.95 20.65ZM19.5 18.1L20.65 16.95C20.8833 16.7167 21 16.45 21 16.15C21 15.85 20.8833 15.5833 20.65 15.35C20.4167 15.1167 20.15 15 19.85 15C19.55 15 19.2833 15.1167 19.05 15.35L17.9 16.5L19.5 18.1ZM18.375 22.075C17.7583 22.6917 17.0167 23 16.15 23C15.2833 23 14.5417 22.6917 13.925 22.075C13.3083 21.4583 13 20.7167 13 19.85C13 18.9833 13.3083 18.2417 13.925 17.625L17.625 13.925C18.2417 13.3083 18.9833 13 19.85 13C20.7167 13 21.4583 13.3083 22.075 13.925C22.6917 14.5417 23 15.2833 23 16.15C23 17.0167 22.6917 17.7583 22.075 18.375L18.375 22.075ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H9.2C9.41667 2.4 9.77933 1.91667 10.288 1.55C10.796 1.18333 11.3667 1 12 1C12.6333 1 13.2043 1.18333 13.713 1.55C14.221 1.91667 14.5833 2.4 14.8 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V11.125C20.6667 11.0417 20.3333 11 20 11C19.6667 11 19.3333 11.025 19 11.075V5H5V19H11.075C11.025 19.3333 11 19.6667 11 20C11 20.3333 11.0417 20.6667 11.125 21H5ZM12 4.25C12.2167 4.25 12.396 4.179 12.538 4.037C12.6793 3.89567 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6793 3.104 12.538 2.962C12.396 2.82067 12.2167 2.75 12 2.75C11.7833 2.75 11.6043 2.82067 11.463 2.962C11.321 3.104 11.25 3.28333 11.25 3.5C11.25 3.71667 11.321 3.89567 11.463 4.037C11.6043 4.179 11.7833 4.25 12 4.25ZM7 9V7H17V9H7ZM7 13V11H17V11.85C16.8667 11.9333 16.7377 12.029 16.613 12.137C16.4877 12.2457 16.3583 12.3667 16.225 12.5L15.725 13H7ZM7 17V15H13.725L12.5 16.225C12.3667 16.3583 12.246 16.4877 12.138 16.613C12.0293 16.7377 11.9333 16.8667 11.85 17H7Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgPrescription
