import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHandCoins = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M11 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13C15 12.4696 14.7893 11.9609 14.4142 11.5858C14.0391 11.2107 13.5304 11 13 11H10C9.4 11 8.9 11.2 8.6 11.6L3 17"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7 21L8.6 19.6C8.9 19.2 9.4 19 10 19H14C15.1 19 16.1 18.6 16.8 17.8L21.4 13.4C21.7859 13.0353 22.0111 12.5323 22.0261 12.0016C22.0411 11.4708 21.8447 10.9559 21.48 10.57C21.1153 10.1841 20.6123 9.95889 20.0816 9.94389C19.5508 9.92889 19.0359 10.1253 18.65 10.49L14.45 14.39"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2 16L8 22"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.9996 11.9C17.6012 11.9 18.8996 10.6016 18.8996 9C18.8996 7.39837 17.6012 6.1 15.9996 6.1C14.398 6.1 13.0996 7.39837 13.0996 9C13.0996 10.6016 14.398 11.9 15.9996 11.9Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6 8C7.65685 8 9 6.65685 9 5C9 3.34315 7.65685 2 6 2C4.34315 2 3 3.34315 3 5C3 6.65685 4.34315 8 6 8Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
export default SvgHandCoins
