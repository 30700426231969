import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQuoteLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M21.75 12H18V9c0-1.655 1.345-3 3-3h.375c.623 0 1.125-.502 1.125-1.125v-2.25c0-.623-.502-1.125-1.125-1.125H21A7.5 7.5 0 0 0 13.5 9v11.25a2.25 2.25 0 0 0 2.25 2.25h6A2.25 2.25 0 0 0 24 20.25v-6A2.25 2.25 0 0 0 21.75 12m-13.5 0H4.5V9c0-1.655 1.345-3 3-3h.375C8.498 6 9 5.498 9 4.875v-2.25C9 2.002 8.498 1.5 7.875 1.5H7.5A7.5 7.5 0 0 0 0 9v11.25a2.25 2.25 0 0 0 2.25 2.25h6a2.25 2.25 0 0 0 2.25-2.25v-6A2.25 2.25 0 0 0 8.25 12"
		/>
	</svg>
)
export default SvgQuoteLeft
