import * as Switcher from '@radix-ui/react-switch'
import { ISwitch } from './ISwitch'
import { cn } from '@/lib/utils'
import { forwardRef } from 'react'

/**
 * Componente Switch utilizando Radix UI's Switcher con opciones personalizables.
 *
 * Este componente renderiza un interruptor (toggle switch) con las siguientes características:
 * - **Label opcional**: Puede mostrar un texto de etiqueta junto al switch.
 * - **Recibir `ref`**: Acepta referencias para controlar el switch programáticamente.
 * - **Extender propiedades de `Switcher.Root`**: Acepta todas las props que `Switcher.Root` permite.
 * - **Contenido personalizado en `Switcher.Thumb`**: Si se pasa `children`, se sobreescribe el contenido del thumb.
 * - **Personalización de tamaño y color**: Permite ajustar el tamaño del thumb y el color del thumb cuando está activo.
 * - **Si deseas cambiar el color de fondo del switch, pasa la prop data-[state=checked]:bg-error-500 o el color que quieras en el className.
 */
const Switch = forwardRef<HTMLButtonElement, ISwitch & Switcher.SwitchProps>(
	(
		{
			label,
			id,
			className,
			htmlFor = label,
			children,
			name,
			value,
			thumbClassName,
			...props
		},
		ref,
	) => {
		return (
				<>
					{label && (
						<label
							className="text-grey-500 text-xs tracking-[0.5px]"
							htmlFor={htmlFor}
							style={{ paddingRight: 15 }}
						>
							{label}
						</label>
					)}
					<Switcher.Root
						ref={ref}
						className={cn(
							`relative w-[52px] h-[32px] rounded-full focus:outline focus:outline-offset-2 focus:outline-2  focus:outline-secondary-500 transition-colors duration-200 group data-[state=checked]:bg-primary-500 bg-grey-100 data-[state=checked]:disabled:opacity-30 disabled:opacity:50 disabled:cursor-not-allowed`,
							className,
						)}
						id={id}
						name={name}
						value={value}
						{...props} // Spread additional Switcher.Root props
					>
						{children ? (
							<Switcher.Thumb asChild>{children}</Switcher.Thumb>
						) : (
							<Switcher.Thumb
								className={cn(
									'absolute top-[4px] bg-white size-6 rounded-full transform transition-transform duration-100 will-change-transform group-data-[state=checked]:translate-x-[-2px] translate-x-[-21px]',
									thumbClassName,
								)}
							/>
						)}
					</Switcher.Root>
				</>
		)
	},
)

Switch.displayName = 'Switch'

export default Switch