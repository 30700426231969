import { cn } from '@/lib/utils'
import { Icon } from '../Icon'
import { ITag } from './ITag'


export default function Tag ({ className, color, iconName, text } : ITag) {
  let tagColor = '';

  switch (color) {
    case 'red':
      tagColor = 'bg-red-100 text-red-600';
      break
    case 'yellow':
      tagColor = 'bg-yellow-100 text-yellow-600';
      break
    case 'blue':
      tagColor = 'bg-primary-50 text-primary-500';
      break
    case 'green':
      tagColor = 'bg-green-100 text-green-600';
      break
    default:
      break
  }

  return (
    <div className={cn('inline-flex items-center space-x-2 px-2 py-1 rounded-lg', 'bg-gray-100 text-gray-600', tagColor, className )}>
      {iconName && <span><Icon name={`${iconName}`}/></span>}
      <span className="font-bold text-sm tracking-wide">{text}</span>
    </div>
  )
}
