import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSend = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<mask
			id="send_svg__a"
			width={24}
			height={24}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'alpha',
			}}
		>
			<path fill="currentColor" d="M0 0h24v24H0z" />
		</mask>
		<g mask="url(#send_svg__a)">
			<path
				fill="currentColor"
				d="M3.5 19.25V4.75L20.711 12zM5 17l11.85-5L5 7v3.692L10.423 12 5 13.308z"
			/>
		</g>
	</svg>
)
export default SvgSend
