import * as React from 'react'
import type { SVGProps } from 'react'
const SvgLaptopMedical = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M8.7 10.8h2.1v2.1a.3.3 0 0 0 .3.3h1.8a.3.3 0 0 0 .3-.3v-2.1h2.1a.3.3 0 0 0 .3-.3V8.7a.3.3 0 0 0-.3-.3h-2.1V6.3a.3.3 0 0 0-.3-.3h-1.8a.3.3 0 0 0-.3.3v2.1H8.7a.3.3 0 0 0-.3.3v1.8a.3.3 0 0 0 .3.3m12.9-6.6a1.806 1.806 0 0 0-1.8-1.8H4.2a1.805 1.805 0 0 0-1.8 1.8v12.6h19.2zm-2.4 10.2H4.8V4.8h14.4zm4.2 3.6h-9.092c-.028.743-.552 1.2-1.228 1.2H10.8c-.7 0-1.238-.655-1.229-1.2H.6a.6.6 0 0 0-.6.6v.6a2.407 2.407 0 0 0 2.4 2.4h19.2a2.407 2.407 0 0 0 2.4-2.4v-.6a.6.6 0 0 0-.6-.6"
		/>
	</svg>
)
export default SvgLaptopMedical
