import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEmailFillOff = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
    <path d="M4.3077 19.5C3.80257 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6923V6.30773C2.5 5.8026 2.675 5.37503 3.025 5.02503C3.375 4.67503 3.80257 4.50003 4.3077 4.50003H19.6923C20.1974 4.50003 20.625 4.67503 20.975 5.02503C21.325 5.37503 21.5 5.8026 21.5 6.30773V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.3077ZM12 12.5577L3.99998 7.44228V17.6923C3.99998 17.782 4.02883 17.8558 4.08653 17.9135C4.14423 17.9712 4.21795 18 4.3077 18H19.6923C19.782 18 19.8557 17.9712 19.9134 17.9135C19.9711 17.8558 20 17.782 20 17.6923V7.44228L12 12.5577ZM12 11L19.8461 6.00001H4.15383L12 11ZM3.99998 7.44228V6.00001V17.6923C3.99998 17.782 4.02883 17.8558 4.08653 17.9135C4.14423 17.9712 4.21795 18 4.3077 18H3.99998V7.44228Z" 
			fill="currentColor"
		/>
	</svg>
)
export default SvgEmailFillOff
