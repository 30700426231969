import * as React from 'react'
import type { SVGProps } from 'react'
const SvgHealthMetrics = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M7 22v-5H2V7h5V2h10v5h5v10h-5v5zM4 11h5q.25 0 .475.125t.35.325l.875 1.3 1.35-4.05q.1-.3.362-.5.263-.2.588-.2.25 0 .475.125t.35.325l1.7 2.55H20V9h-5V4H9v5H4zm5 9h6v-5h5v-2h-5a.96.96 0 0 1-.475-.125 1.2 1.2 0 0 1-.375-.325l-.85-1.3-1.35 4.05q-.1.3-.375.5a1 1 0 0 1-.6.2.96.96 0 0 1-.475-.125.94.94 0 0 1-.35-.325L8.45 13H4v2h5z"
		/>
	</svg>
)
export default SvgHealthMetrics
