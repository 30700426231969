import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQuoteRight = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M21.75 1.5h-6a2.25 2.25 0 0 0-2.25 2.25v6A2.25 2.25 0 0 0 15.75 12h3.75v3c0 1.655-1.345 3-3 3h-.375c-.623 0-1.125.502-1.125 1.125v2.25c0 .623.502 1.125 1.125 1.125h.375c4.144 0 7.5-3.356 7.5-7.5V3.75a2.25 2.25 0 0 0-2.25-2.25m-13.5 0h-6A2.25 2.25 0 0 0 0 3.75v6A2.25 2.25 0 0 0 2.25 12H6v3c0 1.655-1.345 3-3 3h-.375c-.623 0-1.125.502-1.125 1.125v2.25c0 .623.502 1.125 1.125 1.125H3c4.144 0 7.5-3.356 7.5-7.5V3.75A2.25 2.25 0 0 0 8.25 1.5"
		/>
	</svg>
)
export default SvgQuoteRight
