import * as CheckboxRadix from '@radix-ui/react-checkbox'
import { ICheckbox } from './ICheckbox'
import { Icon } from '../Icon'
import { forwardRef } from 'react'
import { cn } from "@/lib/utils"

export const Checkbox = forwardRef<HTMLButtonElement, ICheckbox & CheckboxRadix.CheckboxProps>(({
	defaultChecked = false,
	checked,
	disabled = false,
	children = 'texto obligatorio',
	id = `checkbox ${children}`,
	'data-testid' : dataTestId ='occipital-checkbox',
	variant='outline',
	side = 'left',
	size = `size-6`,
	className,
	...props
}, ref) => {
	return (
		<div
			className={cn(
				'flex items-center gap-2 text-grey-1 shrink-0',
				side === 'right' ? 'flex-row-reverse' : 'flex-row',
				className,
			)}
		>
			<label htmlFor={id}>{children}</label>
			<CheckboxRadix.Root
				ref={ref}
				id={id}
				data-testid={dataTestId}
				className={cn(
					size,
					' shrink-0 flex justify-center items-center rounded-md bg-background-light text-primary-default hover:text-primary-default border-2 border-primary border-solid',
					disabled
						? 'hover:cursor-not-allowed'
						: 'hover:cursor-pointer',
					variant === 'filled'
						? 'aria-checked:bg-primary'
						: 'aria-checked:bg-white',
					'data-[disabled=]:opacity-50',
				)}
				defaultChecked={defaultChecked}
				checked={checked}
				disabled={disabled}
				{...props}
			>
				<CheckboxRadix.Indicator
					className={cn(
						variant === 'filled'
							? 'data-[state=checked]:text-white'
							: 'data-[state=checked]:text-primary',
					)}
				>
					<Icon size={size} name="check" />
				</CheckboxRadix.Indicator>
			</CheckboxRadix.Root>
		</div>
	)
})

Checkbox.displayName = 'Checkbox'
