import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWarningOutline = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2ZM13 16H11V18H13V16ZM13 10H11V14H13V10Z"
			fill="currentColor"
		/>
	</svg>
)
export default SvgWarningOutline
