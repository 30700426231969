const WhatsApp = () => {
  return (
    <svg viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.0781 7.36509C27.7029 5.97598 26.0649 4.87459 24.2596 4.12517C22.4543 3.37574 20.5178 2.99328 18.5631 3.00009C10.3731 3.00009 3.69813 9.67509 3.69813 17.8651C3.69813 20.4901 4.38813 23.0401 5.67813 25.2901L3.57812 33.0001L11.4531 30.9301C13.6281 32.1151 16.0731 32.7451 18.5631 32.7451C26.7531 32.7451 33.4281 26.0701 33.4281 17.8801C33.4281 13.9051 31.8831 10.1701 29.0781 7.36509ZM18.5631 30.2251C16.3431 30.2251 14.1681 29.6251 12.2631 28.5001L11.8131 28.2301L7.13313 29.4601L8.37813 24.9001L8.07813 24.4351C6.84474 22.4655 6.18984 20.189 6.18813 17.8651C6.18812 11.0551 11.7381 5.50509 18.5481 5.50509C21.8481 5.50509 24.9531 6.79509 27.2781 9.13509C28.4293 10.281 29.3417 11.6441 29.9622 13.1452C30.5828 14.6464 30.8992 16.2558 30.8931 17.8801C30.9231 24.6901 25.3731 30.2251 18.5631 30.2251ZM25.3431 20.9851C24.9681 20.8051 23.1381 19.9051 22.8081 19.7701C22.4631 19.6501 22.2231 19.5901 21.9681 19.9501C21.7131 20.3251 21.0081 21.1651 20.7981 21.4051C20.5881 21.6601 20.3631 21.6901 19.9881 21.4951C19.6131 21.3151 18.4131 20.9101 17.0031 19.6501C15.8931 18.6601 15.1581 17.4451 14.9331 17.0701C14.7231 16.6951 14.9031 16.5001 15.0981 16.3051C15.2631 16.1401 15.4731 15.8701 15.6531 15.6601C15.8331 15.4501 15.9081 15.2851 16.0281 15.0451C16.1481 14.7901 16.0881 14.5801 15.9981 14.4001C15.9081 14.2201 15.1581 12.3901 14.8581 11.6401C14.5581 10.9201 14.2431 11.0101 14.0181 10.9951H13.2981C13.0431 10.9951 12.6531 11.0851 12.3081 11.4601C11.9781 11.8351 11.0181 12.7351 11.0181 14.5651C11.0181 16.3951 12.3531 18.1651 12.5331 18.4051C12.7131 18.6601 15.1581 22.4101 18.8781 24.0151C19.7631 24.4051 20.4531 24.6301 20.9931 24.7951C21.8781 25.0801 22.6881 25.0351 23.3331 24.9451C24.0531 24.8401 25.5381 24.0451 25.8381 23.1751C26.1531 22.3051 26.1531 21.5701 26.0481 21.4051C25.9431 21.2401 25.7181 21.1651 25.3431 20.9851Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default WhatsApp