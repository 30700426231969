import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAttachFile = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M17.673 15.644q0 2.436-1.695 4.146T11.85 21.5t-4.132-1.71-1.7-4.146v-8.99q0-1.731 1.202-2.942T10.154 2.5t2.932 1.212q1.202 1.21 1.202 2.942v8.51a2.38 2.38 0 0 1-.707 1.733 2.33 2.33 0 0 1-1.724.718 2.37 2.37 0 0 1-1.735-.713 2.36 2.36 0 0 1-.718-1.739V6.385h1.5v8.778q0 .402.27.677t.672.275a.9.9 0 0 0 .672-.275.93.93 0 0 0 .27-.677V6.644q-.015-1.107-.77-1.876Q11.265 4 10.155 4q-1.107 0-1.871.773a2.58 2.58 0 0 0-.764 1.88v8.991q-.015 1.814 1.254 3.085T11.854 20q1.787 0 3.038-1.271 1.25-1.272 1.28-3.085v-9.26h1.5z"
		/>
	</svg>
)
export default SvgAttachFile
