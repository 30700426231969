import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
		<mask
			id="mask0_8562_49035"
			style={{ maskType: 'alpha' }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="currentColor" />
		</mask>
		<g mask="url(#mask0_8562_49035)">
			<path
				d="M10.8004 12L15.4004 16.6L14.0004 18L8.00039 12L14.0004 6L15.4004 7.4L10.8004 12Z"
				fill="currentColor"
			/>
		</g>
	</svg>
)
export default SvgChevronLeft
