import * as React from 'react'
import type { SVGProps } from 'react'
const SvgEmailFillOn = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 24 24" {...props}>
<path d="M4.3077 19.5C3.80257 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6923V6.30773C2.5 5.8026 2.675 5.37503 3.025 5.02503C3.375 4.67503 3.80257 4.50003 4.3077 4.50003H19.6923C20.1974 4.50003 20.625 4.67503 20.975 5.02503C21.325 5.37503 21.5 5.8026 21.5 6.30773V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.3077ZM12 12.5577L20 7.44228L19.8461 6.00001L12 11L4.15383 6.00001L3.99998 7.44228L12 12.5577Z" 
			fill="currentColor"
		/>
	</svg>
)
export default SvgEmailFillOn
